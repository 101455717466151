<template>
  <f7-page name="mytasks">
    <f7-navbar title="My Tasks" back-link="Back">
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".item-title"
         :value="keyword" 
         @input="keyword = $event.target.value"
         @keypress.native="searchTask($event)"
         @change="refreshTaskList($event)"
      ></f7-searchbar>
    </f7-navbar>

    <div class="card">
      <!-- <form class="list" id="my-form"> -->
 
        
          <f7-list>
        
        <f7-list-item
            class="item-link smart-select filter"
            title="Filter"
            smart-select
            :smart-select-params="{
              openIn: 'popup',
              searchbar: true,
              searchbarPlaceholder: 'Search Filters',
              on: { closed: filter },
            }"
          >
          
            <select name="filter" multiple>
            
              <optgroup label="Status" >
                <option v-for="status in filters.statuses" :key="status.id" :value="'s-' + status.value ">{{status.value}}</option>
              
              </optgroup>
              <optgroup label="Department">
               <option v-for="department in filters.departments" :key="department.id" :value="'d-' + department.value " :selected="departments.includes(department.value)">{{department.value}}</option>
              </optgroup>

               <optgroup label="Shops">
               <option v-for="location in filters.locations" :key="location.id" :value="'l-' + location.value ">{{location.value}}</option>
              </optgroup>

              <optgroup label="Assigned To">
               <option v-for="assigned in filters.assigned_to" :key="assigned.id" :value="'a-' + assigned.id ">{{assigned.full_name}}</option>
              </optgroup>

              <optgroup label="Created By">
               <option v-for="created in filters.created_by" :key="created.id" :value="'c-' + created.id ">{{created.full_name}}</option>
              </optgroup>


            </select>
          </f7-list-item>
          
          <f7-list-item
            v-for="task in tasks"
            :key="task.id"
            :class="removeSpace(task._status.value)"
            :link="'/task/' + task.id"
          >
          {{ task.name}}   {{ task.id}}<span class="assignedto">{{formatTitle(task)}}</span>
          </f7-list-item>
          <Filter/> 
        </f7-list>
  
 
      <div class="data-table-footer"></div>
    </div>
    <f7-toolbar bottom class="bottom-toolbar">
  <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>
  <f7-link ><i class="icon f7-icons color-blue" @click="createdList">person_2_square_stack</i></f7-link>
      <f7-link 
        ><i class="icon f7-icons color-blue" @click="openFilter">slider_horizontal_3</i></f7-link
      >
     <f7-link 
        ><i class="icon f7-icons color-blue" @click="clearFilter">clear</i></f7-link
      >
      <f7-link href="/addtask/"
        ><i class="icon f7-icons color-blue">plus</i></f7-link
      >
      
    </f7-toolbar>
  </f7-page>
</template>
<script>



export default {
  components:{
 
  },
  data() {
    return {
      search:false,
      tasks: "",
      type: "",
      status: "",
      uid:"",
      token:"",
      date_range:{
        start: '',
        end: '',
      },
      statuses:'',
      branches:'',
      departments:'',
      assigned_to:'',
      created_by:'',
      keyword:'',
      filters:{
        departments:'',
        locations:'',
        statuses:'',
        assigned_to:'',
        created_by:''
      }
      
    };
  },
  mounted(){
    this.uid = this.$f7route.params.uid;
  //this.assigned_to = [this.uid];
  // searchbar:clear(function(){alert('here')})
    var credStore =  this.$vlf.createInstance({
      storeName: 'cred'
  });
  credStore.getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
 
  },

  async created() {
//   const taskStore =  this.$vlf.createInstance({
//                 storeName: 'task'
//             });
// await taskStore
//       .getItem("statuses")
//       .then((value) => (this.statuses = value))
//       .catch(function (err) {
//         // This code runs if there were any errors
//         console.log(err);
//       });
   var credStore =  this.$vlf.createInstance({
      storeName: 'cred'
  });
await  credStore.getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });


  // this.assigned_to = [this.$f7route.params.uid];
    const data = {
      status: this.status,
      uid: this.$f7route.params.uid,
      statuses: this.statuses,
      branches: this.branches,
      departments: this.departments,
      assigned_to: this.assigned_to,
      created_by: this.created_by,
      keyword: this.keyword,
      date_range: this.date_range,
    };
    const token = this.token
console.log(data)

    const url = apiUrl + "/api/tasks/usertasks";
// const url = "http://10.10.10.116/servco/api/tasks/usertasks";
    await axios
      .post(url, data,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + TOKEN,
          
        },
       
      })
      .then((response) => {
     
        this.tasks = response.data.tasks;
            console.log(response);
       
      })
      // .then((response) => (this.vacations = response.data.items))
      .catch(function (error) {
        console.log(error);
      });



const filterdata = {
      menus:['locations','department','task_status'],
    };

    const filterurl = apiUrl + "/api/dropdowns/filterlist";
    
    await axios
      .post(filterurl, filterdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization' : 'Bearer ' + TOKEN,
        },
      })
      .then((response) => {
      this.filters.locations = response.data.locations;
      this.filters.departments = response.data.department;
      this.filters.statuses = response.data.task_status;
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

      const userdata = {
      
    };

    const userurl = apiUrl + "/api/users/list";
    
    await axios
      .post(userurl, userdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         'Authorization' : 'Bearer ' + TOKEN,
        },
      })
      .then((response) => {
      this.filters.assigned_to = response.data
      this.filters.created_by = response.data
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  


  },
  methods: {

    loadTask(){

      // alert(this.keyword)
      const data = {
      status: this.status,
      uid: this.$f7route.params.uid,
      statuses: this.statuses,
      branches: this.branches,
      departments: this.departments,
      assigned_to: this.assigned_to,
      created_by: this.created_by,
      keyword: this.keyword,
      date_range: this.date_range,
    };
console.log(data)
    const url = apiUrl + "/api/tasks/usertasks";
// const url = "http://10.10.10.116/servco/api/tasks/usertasks";
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization' : 'Bearer ' + TOKEN,
        },
      })
      .then((response) => {
        this.tasks = response.data.tasks;
        console.log(response);
      })
      // .then((response) => (this.vacations = response.data.items))
      .catch(function (error) {
        console.log(error);
      });
    },
    searchTask(event){

      if(event.keyCode == 13 ){
   
        event.preventDefault();
        this.search = true;
        this.loadTask();
      }

    },
    createdList(){
      this.created_by = [this.uid];
  this.loadTask()

    },
    refreshTaskList(event){
 
      if(this.keyword =='' && this.search == true ){
        this.search =  false;
        this.loadTask();
      }

    },
    filter() {
       const taskStore =  this.$vlf.createInstance({
              storeName: 'task'
          });
      //this code works for accessing selected smart-select items
      const app = this.$f7;
      var sel = app.smartSelect.get(".filter .smart-select").getValue();
      var loc = [];
      var status = [];
      var dept = [];
      var assigned = [];
      var created = [];
      console.log(sel);

      // alert(Array.isArray('sel'))
      sel.forEach(function (v, i) {
        var sp = v.split("-");
        if (sp[0] == "l") {
          loc.push(sp[1]);
        }
        if (sp[0] == "s") {
          status.push(sp[1]);
        }
        if (sp[0] == "d") {
          dept.push(sp[1]);
        }
        if (sp[0] == "a") {
          assigned.push(sp[1]);
        }
        if (sp[0] == "c") {
          created.push(sp[1]);
        }
      });
      if(status.length == 0){
      taskStore.setItem('statuses','');
      this.statuses ='';
      }else{
      taskStore.setItem('statuses',status);
      this.statuses = status;
      }
      if(loc.length == 0){
      this.branches ='';
      }else{
      this.branches = loc;
      }
      if(dept.length == 0){
        this.departments='';
      }else{
      this.departments = dept;
      }
      if(assigned.length == 0){
        // this.assigned_to=[this.uid];
      }else{
        
      this.assigned_to = assigned;
      }
      if(created.length == 0){
        this.created_by='';
      }else{
       
      this.created_by = created;
      this.assigned_to= '';
      }

      // alert(this.type);
      this.loadTask();
    },
    test(arr) {
      // alert(arr.name);
    },
    removeSpace(val){

    val = val.toLowerCase();
    val = val.replaceAll(' ','_');

    return val;
  },
  formatTitle(task){
    if(this.assigned_to != '' && this.assigned_to[0] == this.uid){
      
    }else{
      return task._assigned_to.full_name;
    }

  },
  openFilter(){
    const app = this.$f7;
      app.smartSelect.open(".filter .smart-select")
  },
  clearFilter(){
     const app = this.$f7;
    //  var sel = app.smartSelect.get(".filter .smart-select").getValue();
  
      app.smartSelect.get(".filter .smart-select").unsetValue();

       this.statuses ='';
      this.branches ='';
      this.departments ='';
      this.assigned_to = '';
     this.created_by ='';
     this.loadTask()
     
  },

//end methods
  },
 
 
};
</script>
<style>
.assignedto{
  font-size: .70em;
  font-style:italic;
}
</style>
// https://stackoverflow.com/questions/43812817/how-to-set-optgroup-select-label-in-vue-js 
// https://stackoverflow.com/questions/43922358/access-the-value-of-smart-select-via-javascript-in-framwork7
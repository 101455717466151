<template>
  <f7-page name="partsdaily">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/partsdaily/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title> Daily Parts </f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keypress.native="searchParts($event)"
        @change="clear($event)"
      ></f7-searchbar>
    </f7-navbar>

    <!-- Toolbar-->
    <f7-toolbar bottom>
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>

      <f7-link
        ><i class="icon f7-icons color-blue" @click="clearFilter"
          >clear</i
        ></f7-link
      >
      <f7-link
        ><i class="icon f7-icons color-blue" @click="openFilter"
          >slider_horizontal_3</i
        ></f7-link
      >
      <f7-link href="/partsadd/"
        ><i class="icon f7-icons color-blue">plus</i></f7-link
      >
    </f7-toolbar>
    <div class="card">
    <f7-list>
      <f7-list-item
        class="item-link smart-select filter"
        title="Filter"
        ref="filter"
        smart-select
        :smart-select-params="{
          openIn: 'popup',
          searchbar: true,
          searchbarPlaceholder: 'Search Filters',
          on: { closed: filter },
        }"
    
      >
        <select name="filter" @change="closeList()" >
          <optgroup label="Urgency" >
            <option
              v-for="urgency in urgencies"
              :selected = "selected(urgency.value)"
              class="urgency"
              :key="urgency.id"
              :value="'u-' + urgency.value"

            >
              {{ urgency.value }}
            </option>
          </optgroup>

          <optgroup label="Vendors">
            <option
              v-for="vendor in vendors"
              :key="vendor.id"
              :value="'v-' + vendor.name"
            >
              {{ vendor.name }}
            </option>
          </optgroup>

          <optgroup label="Shops">
            <option
              v-for="location in locations"
              :key="location.id"
              :value="'l-' + location.name"
            >
              {{ location.name }}
            </option>
          </optgroup>
        </select>
      </f7-list-item>
      <f7-list-item
        v-for="part in parts"
        :key="part.id"
        :link="'/part/' + part.id"
        no-chevron
      >
        <div class="card">
          <div class="card-header">
            <span class="left vend" :class="removeSpace(part.vendor.name)" >{{ part.vendor.name }}</span>
            <span>{{ part.user.full_name }}</span>
            <span class="right">{{ part.orderno }}</span>
          </div>

          <div :id="'content' + part.id" class="card-content">
            <div v-if="part.details === ''" class=""></div>
            <div v-else>
              <span class="title">Detail:</span><br /><span class="cont">{{
                part.details
              }}</span>
            </div>
            <div v-if="part.specialInstructions === ''" class=""></div>
            <div v-else>
              <span class="title">Special Request:</span><br /><span
                class="cont"
              >
                {{ part.specialInstructions }}</span
              >
            </div>
          </div>

          <div>
            <f7-badge class="badge" :class="removeSpace(part._urgency.value)">{{
              part._urgency.value
            }}</f7-badge>
            <f7-badge class="badge" :class="removeSpace(part._action.value)">{{
              part._action.value
            }}</f7-badge>
            <f7-badge
              class="badge"
              :class="removeSpace(part._location.value)"
              >{{ part._location.value }}</f7-badge
            >
          </div>
          <div class="card-footer">
            <div v-if="part._status !== null" class="tstamp">
              {{ part._status.value }}
              <span v-if="part._status_by !== null"
                >By {{ part._status_by.full_name }}
              </span>
              <span v-if="part._modified !== null"
                >@ {{ format_timestamp(part.modified) }}
              </span>
            </div>
          </div>
          </div>
       
      </f7-list-item>
    </f7-list>
  </div>
  </f7-page>
</template>
<style>
.card {
  width: 100%;
}
.card-content {
  padding: 0 10px 0 10px;
}
.title {
  font-size: 0.55em;
}
.badge {
  font-size: 0.75em;
}
.tstamp {
  font-size: 0.75em;
}

.panel {
  min-width: 20px;
  max-width: 10vw;
}

.row {
  min-height: 30px;
}

.item {
  background-color: ghostwhite;
}

.t-col {
  font-size: 1.2em;
  text-align: center;
  min-height: 95%;
  width: 95%;
  /* border: 1px solid black; */
}
.eight {
  width: 8%;
}
.ten {
  width: 10%;
}
.twentyfour {
  width: 24%;
}

/* .navbar{
   background-color:yellow;
} */
</style>

<script>
export default {
  data: function () {
    return {
      token: "",
      uid: "",
      parts: "",
      record: "",
      search: false,
      vendors: "",
      locations: "",
      vendor_id: "",
      keyword: "",
      urgencies: "",
      filters: {
        vendor: "",
        urgency: "",
        location: "",
      },
      set:'All Runs',
    };
  },

  async created() {
    const dailypartsStore = this.$vlf.createInstance({
        storeName: "dailyparts",
      });
      await dailypartsStore
      .getItem("vendor")
      .then((value) => (this.filters.vendor = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      await dailypartsStore
      .getItem("location")
      .then((value) => (this.filters.location = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      await dailypartsStore
      .getItem("urgency")
      .then((value) => (this.filters.urgency = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      await dailypartsStore
      .getItem("set")
      .then((value) => (this.set = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
 
    const credStore = this.$vlf.createInstance({
      storeName: "cred",
    });

    await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    await credStore
      .getItem("uid")
      .then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    this.loadData();
   
  },

  methods: {
    loadData() {
      const data = {
        userId: this.uid,
        search: this.search,
        vendor_id: this.vendor_id,
        keyword: this.keyword,
        location: this.filters.location,
        vendor:this.filters.vendor,
        urgency:this.filters.urgency,
      };

      const url = apiUrl + "/api/dailyparts/index";

      console.log(data);
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.parts = response.data.dailyparts;
          this.vendors = response.data.stops.vendors;
          this.locations = response.data.stops.locations;
          this.urgencies = response.data.urgencies;
        })
        .catch(function (error) {
          console.log(error);
        });

      //  }).then(response => console.log(response.data)).catch(function(error){console.log(error)});
      //.then(response => this.posts = response.data.data)
      this.$f7ready((f7) => {
        //  f7.dialog.alert('Component mounted');
      });
      // this.closeList();
    },

    setVendor(val) {
      //  alert(val)
      if (val == "viewall") {
        this.vendor_id = "";
      } else {
        this.vendor_id = val;
      }

      this.loadData();
    },
    lowerCase(value) {
      value = value.replace(/\s/g, "");
      return value.toLowerCase();
    },
    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    show(id) {
      $("#content" + id).show();
    },
    alert(he) {
      alert(he);
    },

    format_timestamp(timestamp) {
      var date = this.$moment(timestamp).format("YYYY-MM-DD");
      var time = this.$moment(timestamp).format("hh:mm a");
      return date + " " + time;
    },
    setDST(date) {
      var dst = this.$moment(date).isDST();
      if (dst == true) {
        return "-04:00";
      } else {
        return "-05:00";
      }
    },
    searchParts(event) {
      this.search = true;
      this.loadData();
    },
    clear(event) {
      if (this.keyword == "") {
        this.search = false;
        this.loadData();
      }
    },
    openFilter() {
      const app = this.$f7;
      app.smartSelect.open(".filter .smart-select");
    
    },
    clearFilter() {
      const app = this.$f7;
      const dailypartsStore = this.$vlf.createInstance({
        storeName: "dailyparts",
      });
      app.smartSelect.get(".filter .smart-select").unsetValue();
      this.filters.urgency = "";
      this.filters.location = "";
      this.filters.vendor = "";
      dailypartsStore.setItem("vendor","");
      dailypartsStore.setItem("location","");
      dailypartsStore.setItem("urgency","");
      dailypartsStore.setItem("set","All Runs");
      this.loadData();
    },
    filter() {
     
      const dailypartsStore = this.$vlf.createInstance({
        storeName: "dailyparts",
      });
      //this code works for accessing selected smart-select items
      const app = this.$f7;
      var sel = app.smartSelect.get(".filter .smart-select").getValue();
      var urg = "";
      var ven = "";
      var loc = "";
      var set = "";

      console.log(sel);

      // alert(Array.isArray('sel'))
      // sel.forEach(function (v, i) {
        let sp = sel.split("-");
        set = sp[1];
        if (sp[0] == "l" ) {
          loc = sp[1];
          ven = sp[1];
        
         }
      
        if (sp[0] == "u" ) {
         urg = sp[1];
       
        }
        if (sp[0] == "v" ) {
          ven = sp[1];
          
        }
      // });
      dailypartsStore.setItem("set", sp[1]);
      this.set =  sp[1];
      if (ven.length == 0) {
        dailypartsStore.setItem("vendor", "");
        this.filters.vendor = "";
      } else {
        dailypartsStore.setItem("vendor", ven);
        this.filters.vendor = ven;
      }
      if (loc.length == 0) {
        dailypartsStore.setItem("location", "");
        this.filters.location = "";
      } else {
        dailypartsStore.setItem("location", loc);
        this.filters.location = loc;
      }
      if (urg.length == 0) {
        dailypartsStore.setItem("urgency", '');
        this.filters.urgency = "";
      } else {
        dailypartsStore.setItem("urgency", urg);
        this.filters.urgency = urg;
      }
     
console.log(this.filters);
      // alert(this.type);
      this.loadData();
    },

    closeList(){
   
      this.$refs.filter.f7SmartSelect.close();
    },
    selected(val){
      if(this.set == val){
        return true;
      }else{
        return false;
      }
    }



    
  },
};
</script>

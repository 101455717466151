<template>
    <div>
            <f7-block
                v-for="note in notes"
                :key="note.id"
            >
     
            <div class="note" :style="note.user_id != userid ? 'background:lightblue' : ''">
              <p
              :id="'note' + note.id" 
              @keydown="updateNote($event,note.id)" 
              >
              <span v-if="note.message"> {{note.message}} </span>
              <span v-else-if="note.note"> {{note.note}} </span>
              </p>
              <div class="note-footer">
              <span v-if="note.user"> {{note.user.full_name}} </span>
              <span v-else-if="note._created_by">{{ note._created_by.full_name }}</span>
               {{formatTime(note.created)}}
               <span v-if="note.user_id == userid"><a @click="editNote(note.id)">Edit</a> <a @click="deleteNote(note.id)">Delete</a></span>
               <span v-if="note.created_by == userid"><a @click="editNote(note.id)">Edit</a> <a @click="deleteNote(note.id)">Delete</a></span>
              </div>
              </div>
            </f7-block>

            <f7-list-input
              ref="add_note"
              class="newNote"
              label="Add Note"
              @keypress.native="saveNote($event)"
              type="textarea"
              placeholder="Type note..."
            >
             
            </f7-list-input>
</div>
</template>

<script>
import { runInThisContext } from 'vm'

export default {
 props:{
   notes:{ 
    type:Array, 
    default:""},
   model: String,
   userid: Number,
   entid: Number,
   ndb:{
    type:String,
    default:"notes"
   }
  },
   
  data(){ 
   return {

      notel:this.notes,
      text: '',
      modl:this.model,
      updateKey: 1,

   }
   },
   mounted(){
    //    this.method()
  //  this.notel = this.notes;
       
      //  console.log(this.model)
      //  console.log(this.userid)
       console.log(this.notel)
       console.log(this.ndb);
       
   },
  //  computed: {
  //   notel() {
  //     return this.notes;//This is needed to make notes update dynamically
  //   },
  // },

   methods:{
       formatTime(time){
      var time = new Date(time);

      return this.$moment(time).format("YYYY-MM-DD h:mm A");
    

    },
   setBadge(num){
      if(num > 0 ){
        return num;
      }else{
        return '';
      }

    },
  
     removeSpace(val){

    val = val.toLowerCase();
    val = val.replaceAll(' ','_');

    return val;
  },
    editNote(id){
      var par = this.$el.querySelector('#note' + id)
      par.setAttribute('contenteditable', 'true');
      par.focus();
    //   par.addEventListener("v-bind:keypress",updateNote($event))
      console.log(par)
    },
    saveNote(event){

        if(event.keyCode == 13 ){
        event.preventDefault();
        // console.log(event.target.value)
        this.addNote(event.target.value)
       
            event.target.value = '';
        }
       
    },
    updateNote(event,id){
        if(event.keyCode == 13 ){
        event.preventDefault();
        console.log('id:'+ id)
        console.log(event.target.innerText)
         event.target.setAttribute('contenteditable', 'false');
        event.target.blur()
        this.updateNoteRecord(event.target.innerText,id)
        }
    },
    addNote(note) {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      var url = apiUrl +  "/api/notes/add";
      if(this.ndb == 'requestnotes'){
       url = apiUrl +  "/api/dailyparts/addnote";
      }
      console.log(url)
      const data = {
          model:this.model,
        entity_id:this.entid,
        type:'note',
        user_id:this.userid,
        message:note,

      }
console.log(data);
   axios.post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + TOKEN,
          },
        })
        .then((response) => {
           console.log(response)
           this.$emit('updateNotes', response.data);       
          this.$emit('notesCount',response.data.length);
     
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    deleteNote(id) {
        confirm('Are you sure you want to delete this note?')
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      var url = apiUrl + "/api/notes/delete";
      if(this.ndb == 'requestnotes'){
       url = apiUrl + "/api/dailyparts/deletenote"; 
      }
      const data = {
    
        id:id,
        user_id:this.userid,
        entity_id:this.entid,
 
      }
     
      console.log(data)
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + TOKEN,
          },
        })

        .then((response) => {
           console.log(response.data)
           this.$emit('updateNotes', response.data);           
          this.$emit('notesCount',response.data.length);
          console.log(this.notes)
        })
        .catch(function (error) {
          console.log(error);
        });
    
    },
  updateNoteRecord(message,id) {
      
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      var url = apiUrl + "/api/notes/edit";
      if(this.ndb == 'requestnotes'){
      url = apiUrl + "/api/dailyparts/editnote"; 
      }
      const data = {
    
        id:id,
        user_id:this.userid,
        message:message,
        model:this.model,
        entity_id:this.entid,
        type:'note',
      }
  
      console.log(data)
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })

        .then((response) => {
           console.log(response.data)
      
        })
        .catch(function (error) {
          console.log(error);
        });
    },

   

   },

}

</script>
<template>
  <f7-page name="tool">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/listcreditrequest/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title> Credit Request </f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-card outline :title="'Company: ' + creditrequest.company_name">
    </f7-card>
    <f7-card outline>
      <f7-list>
        <f7-list-item>
          <f7-card
            :title="'Customer Type: ' + creditrequest._type.value"
          ></f7-card>
        </f7-list-item>
        <f7-list-item>
          <f7-card
            :title="'Priority: ' + creditrequest._priority.value"
            disabled
            :class="removeSpace(creditrequest._priority.value)"
          ></f7-card>
        </f7-list-item>
        <f7-list-item>
          <f7-card
            :title="'Request Reason: ' + creditrequest._priority.value"
            disabled
            :class="removeSpace(creditrequest._reason.value)"
          ></f7-card>
        </f7-list-item>
        <f7-list-item>
          <f7-card
            :title="'Request Status: ' + creditrequest._status.value"
            disabled
            :class="removeSpace(creditrequest._status.value)"
          ></f7-card>
        </f7-list-item>
        <f7-list-item>
          <f7-card
            :title="
              'Reference Status: ' +
              creditrequest.creditapplication._ref_status.value
            "
            disabled
            :class="
              removeSpace(creditrequest.creditapplication._ref_status.value)
            "
          ></f7-card>
        </f7-list-item>
        <f7-list-item>
          <f7-card
            :title="
              'Approval Status: ' +
              creditrequest.creditapplication._status.value
            "
            disabled
            :class="removeSpace(creditrequest.creditapplication._status.value)"
          ></f7-card>
        </f7-list-item>
        <f7-list-item>
          <f7-card
            v-if="creditrequest.creditapplication.amount_requested > 0"
            :title="'Requested Amount: ' + creditrequest.creditapplication.amount_requested"
            disabled
          ></f7-card>
        </f7-list-item>
        <f7-list-item
          v-if="
            creditrequest.request_email_sent == 1 &&
            creditrequest.sent_date != null
          "
        >
          Application Email Sent: {{ formatDate(creditrequest.sent_date) }}
        </f7-list-item>
      </f7-list>
    </f7-card>
    <f7-block strong>
      <f7-row v-if="emailSet == true && emailSent == false">
        <f7-col>
          <f7-button @click="emailApplication()" fill color="green"
            >Email Application</f7-button
          >
        </f7-col>
      </f7-row>
      <f7-row v-if="emailSet == true && emailSent == true">
        <f7-col>
          <f7-button fill color="green" @click="emailApplication()"
            >Re-send Application</f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
  <script>
import { dateTimeMixin } from "../../mixins/dateTimeMixin.js";
export default {
  mixins: [dateTimeMixin],
  components: {},
  data() {
    return {
      emailSet: false,
      emailSent: false,
      creditrequest: {
        _type: [],
        _priority: [],
        name: "",
      },
    };
  },

  async created() {
    var userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    var credStore = this.$vlf.createInstance({
      storeName: "cred",
    });
    await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    const data = {
      id: this.$f7route.params.id,
    };
    const token = this.token;

    const url = apiUrl + "/api/creditrequests/view";
    console.log(data);
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.creditrequest = response.data;
        this.checkEmail(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    emailApplication() {
      const url = apiUrl + "/api/creditrequests/emailapplication";
      const data = {
        id: this.$f7route.params.id,
      };
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    checkEmail(data) {
      if (data.contact_email != null || data.accounting_email != null) {
        this.emailSet = true;
      }
      if (data.sent_date != null) {
        this.emailSent = true;
      }
    },
  },
};
</script>
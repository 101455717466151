<template>

    <f7-page name="addcontact" login-screen>
      <f7-navbar title="Add Contact" back-link="Back"></f7-navbar>
      <f7-block-title class="text-align-center"
        ><h2>Add Contact</h2></f7-block-title
      >
      <f7-list no-hairlines-md form>
        <f7-list-input
          label="First Name"
          type="text"
          name="first_name"
          id="first_name"
          placeholder="First Name"
          :value="first_name"
          @input="first_name = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Last Name"
          type="text"
          :value="last_name"
          @input="last_name = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Phone"
          type="text"
          placeholder="Phone"
          name="phone"
          :value="phone"
          @input="phone = verifyPhone($event.target.value)"
        ></f7-list-input>

        <f7-list-input
          label="Email"
          type="email"
          placeholder="Email"
          name="email"
          :class="emailVerified"
          :value="email"
          @input="email = $event.target.value"
          @blur="verifyEmail($event.target.value)"
        ></f7-list-input>
      </f7-list>
      <f7-list>
        <f7-row tag="p">
          <f7-button
            class="col"
            fill
            large
            raised
            @click.prevent="submitRequest"
            color="green"
            >Submit
          </f7-button>
        </f7-row>

        <f7-row tag="p">
          <f7-button
            class="col"
            fill
            large
            raised
            color="red"
            @click="$f7router.back()"
            >Cancel</f7-button
          >
        </f7-row>
      </f7-list>
    </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      userid:"",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      emailVerified: "",
    };
  },
  async created(){

    
const userStore =  this.$vlf.createInstance({
           storeName: 'user'
       });
await userStore
   .getItem("id")
   .then((value) => (this.userid = value))
   .catch(function (err) {
     // This code runs if there were any errors
     console.log(err);
   });
  },
  methods: {
    verifyPhone(val) {
      if (val.length == 10 || val == "") {
        var text = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        return text;
      } else {
        return val;
      }
    },
    verifyEmail(val) {
      var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
      if (testEmail.test(val)) {
        this.emailVerified = "";
      } else {
        this.emailVerified = "emailNot";
      }
    },
    submitRequest() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      const model = this.$f7route.params.model;
      const addUrl = apiUrl + "/api/" + model + "/addcontact";
      const data = {
        first_name:this.first_name,
        last_name:this.last_name,
        phone:this.phone,
        email:this.email,
        customer_id:this.$f7route.params.id,
        customer_type:'fbcustomer',
        userid:this.userid,
        model:model,
      }
      console.log(data)
      axios
        .post(addUrl, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + TOKEN,
          },
        })
        .then((response) => console.log(response))
        .then(function () {
          router.back({
            force: true,
            ignoreCache: true,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.emailNot {
  color: red;
  border: 2px solid red;
}
</style>

<template>
  <f7-list-item title="Part Numbers">
    
    <li
      class="item-content item-input"
      style="margin-bottom: 3px;padding-left:0;"
      v-for="(part_number, k) in part_numbers"
      :key="k"
    >
    <span class="item-content item-input" style="width:80%;padding-left:0;">
      <input
        class="partno"
        style="background-color: gainsboro; width: 65%;margin-right:0px;text-align: center"
        placeholder="part number"
        type="text"
        name="partno"
        v-model="part_number.partno"
        @blur="update()"
      />
      <input
        class="qty"
        style="background-color: gainsboro; width: 30%;text-align: center"
        placeholder="qty"
        type="number"
        name="qty"
        v-model="part_number.qty"
     @blur="update()"
      />
    </span>
      <i class="icon f7-icons color-red" @click="remove(k)" v-show="k || ( !k && part_numbers.length > 1)">minus_circle_fill</i>
      <i class="icon f7-icons color-green"  @click="add(k)" v-show="k == part_numbers.length-1">plus_circle_fill</i>
    </li>
  </f7-list-item>
</template>
<script>
export default {
  props:{
   partNumbers:{ 
    type:Array, 
    default:""
  },
   },
  data() {
    return {
      part_numbers: [
        {
          partno: "",
          qty: "",
        },
      ],
      int: 1,
    };
  },
  methods: {
    add(index) {
      this.part_numbers.push({ partno: "",qty:"" });
    },
    remove(index) {
      this.part_numbers.splice(index, 1);
    },
   update(){
    this.$emit('updatePartNumbers', this.part_numbers)
      // console.log(this.part_numbers)
    }
  }
};
</script>
// https://demo.smarttutorials.net/vuejs-dynamically-add-remove-input-textbox/

<template>
  <!-- <div id="this-customer"> -->
  <f7-page name="prospect">
    <f7-navbar 
      >
      <f7-nav-left>
        <f7-link icon-ios="f7:chevron_left" icon-md="material:chevron_left"  href="/prospectlist/">Back</f7-link>
      </f7-nav-left>
      <f7-nav-title>
        Prospect
      </f7-nav-title>
      </f7-navbar>
    <f7-card outline :title="prospect.company_name" @taphold.native="testTap">
    </f7-card>

    <f7-list accordion-list inset v-if="numbFbCust >0">
      <f7-list-item
          id="contacts"
          accordion-item
          title="Possible Customer Match"
          :badge="numbFbCust"
          badge-color="blue"
        >
        <f7-accordion-content id="">
        <f7-list-item
        v-for="cust in fbcustomers"
                    :key="cust.id"
                    class=""
        >
{{ cust.company_name }} 
<a 
@click="matchCustomer(cust.id,cust.company_name)"
>
Match
</a>
        </f7-list-item>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>

    <div class="upper-area">
      <f7-list accordion-list inset>
        <f7-list-item
          id="contacts"
          accordion-item
          title="Contacts"
          :badge="numbContacts"
          badge-color="blue"
        >
          <f7-accordion-content id="">
            <div>
              <table class="data-table">
                <thead class="bg-color-white">
                  <tr>
                    <th class="label-cell orient">
                      <span class="addContact"
                        ><a :href="'/addcontact/' + prospect.id + '/model/prospects'"> +</a></span
                      >Name
                    </th>
                    <!-- <th class="label-cell orient">Last Name</th> -->
                    <th class="label-cell">Phone</th>
                    <!-- <th class="label-cell orient">Email</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="contact in customcontacts"
                    :key="contact.id"
                    class=""
                  >
                    <td class="label-cell">
                      {{ contact.first_name }} {{ contact.last_name }}
                    </td>
                    <!-- <td class="label-cell">{{ contact.last_name }}</td> -->
                    <td class="label-cell">
                      {{ contact.phone }}
                      <span
                        class="deleteContact"
                        @click="deleteContact(contact.id)"
                        >x</span>
                    </td>
                    <!-- <td class="label-cell">{{ contact.email }}</td> -->
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </f7-accordion-content>
        </f7-list-item>
       
        <f7-list-item
          id="salescall"
          accordion-item
          title="Sales Calls"
          :badge="numb_calls"
          badge-color="green"
        >
          <f7-accordion-content id="calldata">
            <f7-list-item
              v-if="newSalesCall == true"
              :link="'/salescall/0/customerid/' + prospect.id +'/model/prospects'"
              title="New Sales Call"
            ></f7-list-item>
            <f7-list-item
              v-for="salescall in salescalls"
              :key="salescall.id"
              :link="
                '/salescall/' + salescall.id + '/customerid/' + prospect.id +'/model/prospects'"
              
            >
              {{ salescall.call_type }} by {{ salescall.user.full_name }} on
              {{ formatDate(salescall.created) }}
            </f7-list-item>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item
          id="note"
          accordion-item
          title="Notes"
          :badge="noteCount"
          badge-color="red"
        >
          <f7-accordion-content id="note">
            <Notes
              v-if="loadNotes == true"
              :model="model"
              :notes="prospect.notes"
              :userid="user.id"
              :entid="prospect.id"
              @notesCount="setNoteCount"
              @notesUpdate="noteRecord"
              @updateNotes="setNotes"
            />
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item
          accordion-item
          title="Pictures"
          :badge="picCount"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
              v-if="pics.length > 0"
              pagination
              navigation
              scrollbar
              :loop="false"
              :speed="500"
              :slidesPerView="1"
              :spaceBetween="20"
            >
              <f7-swiper-slide
                v-for="(pic, index) in pics"
                :key="index"
                :title="pic"
                @taphold.native="deletePic(pic)"
              >
                <figure>
                  <figcaption>{{ formatTitle(pic) }}</figcaption>
                  <img
                    class="tapHold"
                    :src="domain + fileUrl + pic"
                    @dblclick="deletePic(pic)"
                  />
                </figure>
              </f7-swiper-slide>
              <f7-swiper-slide
                ><div v-on:click="opencam">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div></f7-swiper-slide
              >
            </f7-swiper>

            <f7-block v-else-if="pics.length == 0" class="upload_icon">
              <div v-on:click="opencam">
                <div>Add image...</div>
                <i class="icon f7-icons color-blue slider_add">camera</i>
              </div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item
          accordion-item
          title="Documents"
          :badge="docCount"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
              v-if="docs.length > 0"
              pagination
              navigation
              scrollbar
              :speed="500"
              :slidesPerView="3"
              :spaceBetween="20"
            >
              <f7-swiper-slide
                v-for="(doc, index) in docs"
                :key="index"
                @taphold.native="deleteDoc(doc)"
              >
                <div
                  class="tapHold"
                  style="padding: 10px"
                  @dblclick="deleteDoc(doc)"
                >
                  <iframe
                    type="application/pdf"
                    :src="domain + fileUrl + doc + '\#zoom=FitW'"
                  ></iframe>
                </div>
              </f7-swiper-slide>

              <f7-swiper-slide
                ><div v-on:click="upload_file">
                  <div>Add File...</div>
                  <i class="icon f7-icons color-blue slider_add"
                    >square_arrow_up</i
                  >
                </div></f7-swiper-slide
              >
            </f7-swiper>

            <f7-block v-else-if="docs.length == 0">
              <div v-on:click="upload_file" class="upload_icon">
                <div>Add File...</div>
                <i class="icon f7-icons color-blue slider_add"
                  >square_arrow_up</i
                >
              </div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-popup id="camera" class="cam-popup">
        <!-- <f7-popup id="camera" class="cam-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
        <f7-page>
          <f7-navbar title="Upload Pictures">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <Camera
            :model="model"
            :item="prospect"
            :uid="user.id"
            @picList="setPics"
          />
          <!-- fbcustomer_133539_DFSASDF_1685132228 -->
        </f7-page>
      </f7-popup>
      <f7-popup id="fileloader" class="file-popup">
        <!-- <f7-popup id="fileloader" class="file-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
        <f7-page>
          <f7-navbar title="Upload Files">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <FileLoader
            :model="model"
            :item="customer"
            :uid="user.id"
            @picList="setPics"
            @docList="setDocs"
          />
        </f7-page>
      </f7-popup>
    </div>

    <f7-toolbar bottom class="bottom-toolbar">
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>

      <f7-link><i class="icon f7-icons color-blue">square_arrow_up</i></f7-link>
      <f7-link
        ><i class="icon f7-icons color-blue">chat_bubble_text</i></f7-link
      >
      <f7-link><i class="icon f7-icons color-blue">camera</i></f7-link>
    </f7-toolbar>
  </f7-page>
  <!-- </div> -->
</template>
  
  <script>
import Buttons from "../../components/Buttons.vue";
import Notes from "../../components/Notes.vue";
import Camera from "../../components/CameraComp.vue";
import FileLoader from "../../components/FileLoader.vue";
import { dateTimeMixin } from "../../mixins/dateTimeMixin.js";
import { pictureMixin } from "../../mixins/pictureMixin.js";
import { runInThisContext } from "vm";
export default {
  mixins: [pictureMixin, dateTimeMixin],
  components: {
    Buttons,
    Notes,
    Camera,
    FileLoader,
  },
  data() {
    return {
      id: "",
      token: "",
      customer: {},
      fbcustomers:{},
      numbFbCust:0,
      prospect:{},
      customercontacts: [],
      customcontacts: [],
      numbContacts: "",
      yearly_sales: "",
      ytd_sales: "",
      numb_calls: "",
      noteCount: "",
      docCount: "",
      notes: [],
      pics: [],
      docs: [],
      picCount: 0,
      salescalls: {},
      salescallCount: "",
      newSalesCall: true,
      model: "Prospects",
      loadNotes: false,
      newContact: false,
      user: {
        first_name: "",
        last_name: "",
        id: 0,
        shop: "",
        token: "",
      },
      domain: "",
      fileUrl: "/webroot/files/Prospects/doc/",
    };
  },
  mounted() {
    this.id = this.$f7route.params.id;
    this.loaded = true;
    this.userid = "4";
  },
  async created() {
    var user = this.$vlf.createInstance({
      storeName: "user",
    });
    await user
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await user
      .getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    const data = {
      id: this.$f7route.params.id,
    };
    this.domain = apiUrl;
    const url = apiUrl + "/api/prospects/prospectdetails";

    console.log(data);
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        console.log(response);
        this.prospect = response.data.prospect;
        this.fbcustomers = response.data.fbcustomers;
        this.notes = response.data.prospect.notes;
        this.salescalls = response.data.prospect.salescalls;
        this.customcontacts = response.data.prospect.customcontacts;
        this.pics = response.data.pics;
        this.docs = response.data.docs;
        this.picCount = this.setBadge(response.data.pics.length);
        this.docCount = this.setBadge(response.data.docs.length);
        this.numbFbCust = this.setBadge(response.data.fbcustomers.length);
        this.numbContacts = this.setBadge(response.data.prospect.customcontacts.length);
        this.loadNotes = true;
        console.log(this.notes);
        this.noteCount = this.setBadge(response.data.prospect.notes.length);
        this.numb_calls = this.setBadge(
          response.data.prospect.salescalls.length
        );
        console.log(this.salescalls);
        this.setNewSalesCall(this.salescalls[0].created);
      })
      // .then((response) => (this.vacations = response.data.items))
      .catch(function (error) {
        console.log(error);
      });

    const url2 = apiUrl + "/api/sales/yearlysales";
    const data2 = {
      formatted_name: this.customer.formatted_name,
    };

  
    console.log(this.customer);
  },
  methods: {
    opencam() {
      const app = this.$f7;
      app.popup.open("#camera");
    },
    upload_file() {
      const app = this.$f7;
      app.popup.open("#fileloader");
    },
    setNoteCount(noteCount) {
      this.noteCount = this.setBadge(noteCount);
      this.notes = notes;
    },
    setSalescallCount(count) {
      console.log(count);

      this.SalescallCount = this.setBadge(count);
    },
    noteRecord(notesUpdate) {
      console.log("here");
      console.log(notesUpdate);
      this.salescall.notes = notesUpdate;
    },
    setDocs(docs) {
      this.docs = docs;
      this.docCount = this.setBadge(docs.length);
    },

    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    testTap() {
      alert("yes");
    },
    setNewSalesCall(created) {
      var cur = new Date();
      var created = new Date(created);
      console.log(created);
      cur = this.$moment(cur).format("YYYY-MM-DD");
      created = this.$moment(created).format("YYYY-MM-DD");
      if (created == cur) {
        this.newSalesCall = false;
      }
      console.log(this.newSalesCall);
    },
    deletePic(pic) {
      var del = confirm("Delete this image?");

      if (del == true) {
        this.sendDeleteRequest(pic);
      }
    },
    deleteDoc(doc) {
      var del = confirm("Delete this document?");

      if (del == true) {
        this.sendDeleteRequest(doc);
      }
    },
    sendDeleteRequest(filename) {
      const data = {
        filename: filename,
      };
      console.log(data);
      const url = apiUrl + "/api/prospects/deletedoc";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response);
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
          this.docs = response.data.docs;
          this.docCount = this.setBadge(response.data.docs.length);
          // console.log(response)
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addContact() {
      this.newContact = true;
    },
    setNotes(notes) {
      console.log(notes);
      this.prospect.notes = notes;
    },
    matchCustomer(id,customer){
      if (confirm("Are you sure you want to match this prospect with " + customer + " ?") == true) {
        const url = apiUrl + "/api/prospects/match";
        const data = {
          id: this.$f7route.params.id,
          fbcustomer_id: id,
          userid: this.user.id,
        };
        axios
          .post(url, data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + TOKEN,
            },
          })
          .then((response) => {
            console.log(response);
            const router = this.$f7router;
      
          router.navigate('/customer/' + id);
                  });
      }
    },

    deleteContact(id) {
      if (confirm("Are you sure you want to delete this contact?") == true) {
        const url = apiUrl + "/api/prospects/deletecontact";
        const data = {
          id: id,
          customerid: this.$f7route.params.id
        };
        axios
          .post(url, data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + TOKEN,
            },
          })
          .then((response) => {
            console.log(response);
            this.customcontacts = response.data.customcontacts;
            this.numbContacts = this.setBadge(
              response.data.customcontacts.length + this.customercontacts.length
            );
          });
      }
    },
  },
};
</script>
  <style>
/* .input{
    min-width:10px;
  } */
.addContact {
  padding-right: 20px;
  font-size: 1.5em;
}
.deleteContact {
  font-size: 1.5em;
  float: right;
  color: red;
}
</style>
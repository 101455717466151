<template>
    <f7-page name="newinspection">
        <f7-list strong-ios outline-ios dividers-ios>
            <f7-list-item title="Unit" smart-select ref="unit" :smart-select-params="{
                openIn: 'popup',
                searchbar: true,
                searchbarPlaceholder: 'Search Units',
            }">
                <select id="unit" name="unit" v-model="selectedUnit">
                    <option v-for="unit in units" class="unit_select" :key="unit.id" :value="unit.id"
                        :selected="unit_id == unit.id">
                        {{ unit.nickname }}
                    </option>
                </select>
            </f7-list-item>
        </f7-list>

        <f7-navbar title="Inspection" back-link="Back"></f7-navbar>
        <f7-list>
            <f7-list-item :title="'Inspection Date: ' + inspection_date">
            </f7-list-item>

            <f7-list-item :title="'Inspector: ' + user.first_name + ' ' + user.last_name">
            </f7-list-item>
        </f7-list>

        <f7-list-item>
            <f7-block strong outline-ios>
                <f7-row>
                    <f7-col>
                        <f7-button fill color="green" @click="start">Start</f7-button>
                    </f7-col>
                    <f7-col>
                        <f7-button fill color="red" href="/">Cancel</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </f7-list-item>

    </f7-page>
</template>



        <script>
            export default {

      data() {
        return {
          selectedUnit: 0,
          unit_id: 0,
          test: "",
          maintenanceItems: [8, 11],
          inspections: "",
          items: "",
          inspector: "",
          units: "",
          instructions: "",
          inspection:[],
          inspection_date: "2024-01-18",
          domain: apiUrl,
          fileUrl: "/webroot/files/Inspections/doc/",
          model: "Inspections",
          tid: "",
          pics: "",
          picCount: "",
          notes: [],
          noteCount: "",
          user: {
            first_name: "",
            last_name: "",
            id: "",
            shop: "",
            token: "",
          },
        };
      },

      async created() {
        var userStore = this.$vlf.createInstance({
          storeName: "user",
        });
        userStore
          .getItem("first_name")
          .then((value) => (this.user.first_name = value))
          .catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
          });
        userStore
          .getItem("last_name")
          .then((value) => (this.user.last_name = value))
          .catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
          });
          userStore
          .getItem("id")
          .then((value) => (this.user.id = value))
          .catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
          });
          userStore
          .getItem("token")
          .then((value) => (this.user.token = value))
          .catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
          });
     
  

        const data = {
          keyword: this.keyword,
          limit: this.limit,
          shop: this.shop,
          sales_rep: this.first_name + " " + this.last_name,
          showall: this.showall,
        };
        const token = this.user.token;
        console.log(data);
        console.log(this.user.token);
        const url = apiUrl + "/api/inspections/inspection";

        await axios
          .post(url, data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + TOKEN,
            },
          })
          .then((response) => {
            this.inspection = response.data.inspection;
            this.items = response.data.items;
            this.inspector = response.data.inspector;
            this.units = response.data.units;
            this.instructions = response.data.instructions;
          })
          .finally(() => {
            this.$refs.unit.f7SmartSelect.setValue(this.selectedUnit);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      mounted() {
        this.domain = apiUrl;
      },
      methods: {
        start(){
            alert('Start Inspection')
        }

      },
          }
      
        </script>

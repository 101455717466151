<style>
.info {
  background-color: lightblue;
}
.success {
  background-color: lightgreen;
}
.warning {
  background-color: lightyellow;
}
.danger {
  background-color: lightpink;
}

.land {
  font-size: 0.8em;
}
.port {
  font-size: 0.6em;
}

.data-table th,
.data-table td {
  padding-right: 6px;
}
.comeback-color {
  background-color: #ed5a70;
}

.th-sticky {
  position: sticky !important;
  top: 0px !important;
  z-index: 99 !important;
  box-shadow: 5px 1px #e0e0e0 !important;
}

.hide {
  display: none;
}
 @media only screen and (min-width:480px) {
  .orient {
    font-size: 0.8em;
  }
  .orient-date {
    font-size: 0.8em;
  }
 
}

/*iPad Portrait oriented styles */

@media only screen and (max-width: 479px) {
  .orient {
    font-size: 0.6em;
  }
  .orient-date {
    font-size: 0.6em;
  }
  .show {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  .orient {
    font-size: 1em;
  }
  .orient-date {
    font-size: 0.8em;
  }
 
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .orient {
    font-size: 0.8em;
  }
  .orient-data {
    font-size: 0.8em;
  }

}
</style>
<template>
  <f7-page name="monthly">
    <f7-navbar :title="title" back-link="Back"></f7-navbar>

    <div class="card">
      <table class="data-table">
        <thead class="bg-color-white th-sticky">
          <tr>
            <th class="label-cell orient">Customer</th>
            <th class="label-cell orient">Inv Date<br />SO Number</th>
            <th class="label-cell orient show">Description</th>
            <!-- <th class="numeric-cell">SO Number</th> -->
            <th class="label-cell orient">Actual<br />Billed</th>
            <!-- <th class="numeric-cell">Billed</th> -->
            <th class="label-cell orient">Eff</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="ef in eff"
            :key="ef.id"
            v-bind:class="set_color(ef.actual_hours, ef.complaint_hours)"
          >
            <td class="label-cell orient">
              {{ format_customer_name(ef.customer) }}
            </td>
            <td class="label-cell orient-date">
              {{ ef.date_invoiced }}<br />{{ ef.so }}
            </td>
            <td class="label-cell orient show">{{ ef.complaint_note }}</td>
            <!-- <td class="numeric-cell long_cust">{{ef.so}}</td> -->
            <td class="label-cell orient">
              {{ format_numbers(ef.actual_hours) }}<br />{{
                format_numbers(ef.complaint_hours)
              }}
            </td>
            <!-- <td class="numeric-cell long_cust">{{format_numbers(ef.complaint_hours)}}</td> -->
            <td class="label-cell orient">
              {{ set_eff(ef.actual_hours, ef.complaint_hours) }}%
            </td>
          </tr>
        </tbody>
      </table>

      <table class="data-table" v-show="comeback != null">
        <thead class="bg-color-white">
          <br />
          <tr>
            Comebacks
          </tr>
          <tr>
            <th class="label-cell orient">Credit Number</th>
            <th class="label-cell orient">Credit Date</th>
            <th class="label-cell orient show">Description</th>
            <th class="label-cell orient">Adjusted Hours</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="cb in comeback" :key="cb.id" class=""></tr>
          <tr v-for="cb in comeback" :key="cb.id" class="comeback-color">
            <td class="label-cell orient">{{ cb.credit_no }}</td>
            <td class="label-cell orient-date">{{ cb.credit_date }}</td>
            <td class="label-cell orient show">{{ cb.description }}</td>
            <td class="label-cell orient">{{ cb.hour_adj }}</td>
          </tr>
        </tbody>
      </table>
      <div class="data-table-footer"></div>
    </div>
  </f7-page>
</template>

<script>
export default {
  data: function () {
    return {
      eff: null,
      bonus: null,
      comeback: null,
      isHidden: "",
      year: "2020",
      month: "12",
      orient: "land",
      isPort: null,
      wasPort: -1,
      title: "",
      secure: "",
      first_name: "",
      last_name: "",
      tech: "",
    };
  },

  mounted() {
    // alert(window.screen.orientation.type)
    // this.isPort = (window.innerHeight > window.innerWidth);
    this.year = this.$f7route.params.year;
    this.month = this.$f7route.params.month;
  },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("secure")
      .then((value) => (this.secure = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
      var tch = this.first_name + this.last_name;
      const tchs = tch.split('.');
      if(tchs.length > 1){
      tch = tchs[1].trim();
      }
    this.tech = tch.toLowerCase();
    
    const data = {
      secure: this.secure,
      tech: this.tech,
      year: this.year,
      month: this.month,
    };
    console.log(data);

    const url =
      "https://data.truckserviceco.com/eff_report/dashboard/fetch_data/mobile/index.php";
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        
        },
      })
      .then((response) => {
        console.log(response.data);
        this.eff = response.data.eff_details;
        this.bonus = response.data.bonus;
        this.comeback = response.data.comeback;
        console.log(this.comeback);
      })
      // .then((response) => (this.eff = response.data.items))
      .catch(function (error) {
        console.log(error);
      });

    this.title = this.month_name(this.month) + " " + this.year + " Monthy Eff";
  },

  methods: {
    set_eff(act, bill) {
      var efficency = bill / act;
      efficency = efficency * 100;
      return efficency.toFixed(2);
    },
    format_numbers(numb) {
      return numb.toFixed(2);
    },

    format_customer_name(name) {
      name = name.split("(");
      return name[0];
    },
    set_color(act, bill) {
      var cls = "";
      var efficency = bill / act;
      efficency = efficency * 100;
      switch (true) {
        case efficency > 99.9:
          cls = "success";
          break;
        case efficency > 89.9 && efficency < 100:
          cls = "info";
          break;
        case efficency > 79.9 && efficency < 90:
          cls = "warning";
          break;
        case efficency < 80:
          cls = "danger";
          break;

        default:
          cls = "";
      }
      return cls;
    },
    cust_font(cust) {
      var name = cust.split("(");
      cust = name[0];
      var len = cust.length;
      if (len > 15) {
        return "label-cell long_cust";
      } else {
        return "label-cell";
      }
    },
    month_name(mon) {
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return month[mon - 1];
    },
  },
};
</script>

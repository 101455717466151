
<template>
      <f7-page name="salescalllist" >
        <f7-navbar title="Customer List" back-link="Back">
          <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".item-title"
         :value="keyword" 
         @input="keyword = $event.target.value"
         @keypress.native="searchTask($event)"
         @change="refreshTaskList($event)"
      ></f7-searchbar>
        </f7-navbar>
  
    <div class="card" >
   
      <f7-list>

      <f7-list-item
            v-for="customer in customers"
            :key="customer.id"
          >
          {{ customer.company_name}}  
          </f7-list-item>
        
        </f7-list>

  


   
    <div class="data-table-footer"></div>
  </div>
    <f7-toolbar bottom class="bottom-toolbar">
 
  <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>
  <f7-link ><i class="icon f7-icons color-blue" >person_2_square_stack</i></f7-link>
      <f7-link 
        ><i class="icon f7-icons color-blue">slider_horizontal_3</i></f7-link
      >
     <f7-link 
        ><i class="icon f7-icons color-blue" >clear</i></f7-link
      >
      <f7-link href="/addtask/"
        ><i class="icon f7-icons color-blue">plus</i></f7-link>
      
        </f7-toolbar>
      </f7-page>
</template>
<script>
export default {
  components:{
 
  },
  data() {
    return {
      search:false,
      keyword:'',
      limit:20,
      customers:'',
    };
  },
  mounted(){
    this.uid = this.$f7route.params.uid;
  this.assigned_to = [this.uid];
  // searchbar:clear(function(){alert('here')})
    var credStore =  this.$vlf.createInstance({
      storeName: 'cred'
  });
  credStore.getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
 
  },
  
  async created() {

   var credStore =  this.$vlf.createInstance({
      storeName: 'cred'
  });
await  credStore.getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

      this.assigned_to = [this.$f7route.params.uid];
    const data = {
      keyword: this.keyword,
      limit:this.limit,
    };
    const token = this.token
console.log(data)
console.log(this.token)
    const url = apiUrl + "/api/fbcustomers/customersearch";

    await axios
      .post(url, data,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + this.token,
          
        },
       
      })
      .then((response) => {
     
        this.customers = response.data;
            console.log(response.data);
       
      })
      // .then((response) => (this.vacations = response.data.items))
      .catch(function (error) {
        console.log(error);
      });
      

  },

  methods: {

loadTask(){

  // alert(this.keyword)
  const data = {
  keyword: this.keyword,
  limit:this.limit,
};
console.log(data)
const url = apiUrl + "/api/fbcustomers/customersearch"

axios
  .post(url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization' : 'Bearer ' + this.token,
    },
  })
  .then((response) => {
    this.customers = response.data;
    console.log(response);
  })
  // .then((response) => (this.vacations = response.data.items))
  .catch(function (error) {
    console.log(error);
  });
},
searchTask(event){

  if(event.keyCode == 13 ){

    event.preventDefault();
    this.search = true;
    this.loadTask();
  }

},
  },


}

</script>
<template>
  <f7-page name="addtask">
    <f7-navbar title="Add Task" back-link="Back"></f7-navbar>
    <f7-list>
        <f7-list-input
        label="Task Name"
        type="text"
        placeholder="Task Name..."
        clear-button
        :value="task.name" 
        @input="task.name = $event.target.value"
    />
        <f7-list-input
        label="Task Description"
        type="textarea"
        placeholder="Description"
        clear-button
        :value="task.description" 
         @input="task.description = $event.target.value"
    />
    <f7-list-input
    label="Due Date"
    type="date"
    :value="task.due"
    placeholder="Please choose..."
  />
  
    <UserSearch 
    fieldname="assigned_to"
    title="Assigned To"
    @userUpdated="updateAssigned"/>

    <DepartmentList @departmentUpdated="updateDepartment"/>
    <ShopList @branchUpdated="updateBranch" />

    
    <f7-block strong>
     <f7-row>
      <f7-col>
        <f7-button fill color="red" @click="$f7router.back()">Cancel</f7-button>
      </f7-col>
      <f7-col>
        <f7-button fill color="green"  @click.prevent="addTask">Add Task</f7-button>
      </f7-col>
    
    </f7-row>
      </f7-block>
    
    </f7-list>
    
  </f7-page>
</template>
  <script>
import UserSearch from '../../components/UserSearch.vue'
import DepartmentList from '../../components/DepartmentList.vue'
import ShopList from '../../components/ShopList.vue'

    export default {
      components: {
          UserSearch,
          DepartmentList,
          ShopList
      },

      data() {
        return {
            uid:'',
            user_id:'',
            token:'',
            task:{
                name:'',
                description:'',
                due:'',
                branch:'',
                department:'',
                assigned_to:'',
                created_by:'',
                modified_by:'',
            }

        };
      },
   async mounted(){
     
    var user =  this.$vlf.createInstance({
        storeName: 'user'
    });
    var credStore =  this.$vlf.createInstance({
        storeName: 'cred'
    });

    user.getItem("id")
      .then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

       credStore.getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });



    this.task.due = this.setDefaultDate()
  
  },
      methods: {
          setDefaultDate(){
        let d = new Date();

       return this.$moment(d).format("YYYY-MM-DD");
          },

          updateBranch(val){
              this.task.branch = val;
              console.log('branch:' + this.task.branch)
          },
           updateDepartment(val){
              this.task.department = val;
              console.log('department:' + this.task.department)
          },
           updateAssigned(val){
 
              this.task.assigned_to = val;
              console.log('assigned:' + this.task.assigned_to)
          },

          addTask(){
        const self = this;
      const app = self.$f7;
      const router = self.$f7router;

               this.task.created_by = this.uid;
              this.task.modified_by = this.uid;
        const data = this.task;
        console.log(data)

    const url = apiUrl + "/api/tasks/add";
    
     axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization' : 'Bearer ' + TOKEN,
        },
      })
      .then((response) => {
      router.back({
            force: true,
            ignoreCache: true
          });
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
          },
       

      }
    };
  </script>


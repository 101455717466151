<template>
  <div>
    <f7-list>
      <f7-list-input
        label="Customer"
        class="searchbar-demo tapHold"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keyup.native="searchCustomers($event), showList()"
        @keydown.native="isTab($event)"
        @dblclick.native="clearValue()"
        @tapHold.native="clearValue()"
      ></f7-list-input>
    </f7-list>
    <f7-popover class="popover-menu">
      <f7-list>
        <f7-list-item
          v-for="customer in customers"
          :key="customer.id"
          :value="customer.company_name"
          @click="selectCustomer(customer.id, customer.company_name)"
          >{{ customer.company_name }}</f7-list-item
        >
      </f7-list>
    </f7-popover>
  </div>
</template>

<script>
export default {
  props: {
    customer_id: [Number, String],
  },
  data() {
    return {
      custID: this.customer_id,
      keyword: "",
      showall: true,
      fieldname: "customer",
      customers: [],
      customer_name: "",
      customer: "",
      customer_detail: {
        customer: "",
        customer_id: "",
      },
      shop: "",
      first_name: "",
      last_name: "",
      show_all: "",
      limit: 10,
      custsch: false,
    };
  },

  async created() {
    this.custID = this.$f7route.params.custid;

    if (this.custID != 0) {
      const data = {
        id: this.custID,
      };
      const url = apiUrl + "/api/fbcustomers/customer";

      await axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.customer_name = response.data.company_name;
          this.keyword = response.data.company_name;
          this.customer = response.data.company_name;
          this.custID = response.data.id;
          this.customer_detail.customer = response.data.company_name;
          this.customer_detail.customer_id = response.data.id;
          this.$emit("customerDetails", this.customer_detail);
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("false");
    }
  },

  methods: {
    isTab(e) {
      this.customer = this.keyword;
      this.customer_name = this.keyword;
      this.customer_detail.customer = this.keyword;
      this.customer_detail.customer_id = "";
      this.$emit("customerDetails", this.customer_detail);
    },
    searchCustomers(e) {
      if (e.which == 13) {
        this.customer = this.keyword;
        this.customer_name = this.keyword;
        this.customer_detail.customer = this.keyword;
        this.customer_detail.customer_id = "";
        this.$emit("customerDetails", this.customer_detail);
      } else {
        this.customer_name = this.keyword;

        const data = {
          keyword: this.keyword,
          limit: this.limit,
          shop: this.shop,
          sales_rep: this.first_name + " " + this.last_name,
          showall: this.showall,
        };
        console.log(data);
        const url = apiUrl + "/api/fbcustomers/customersearch";

        axios
          .post(url, data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + TOKEN,
            },
          })
          .then((response) => {
            this.customers = response.data;
            if (response.data.length < 1) {
              this.closeList();
            }

            console.log(response.data);
          })
          // .then((response) => (this.vacations = response.data.items))
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    selectCustomer(id, name) {
      console.log(id, name);
      this.keyword = name;
      this.customer_name = name;
      this.custID = id;
      this.customer = name;
      this.custsch = false;
      this.customer_detail.customer = name;
      this.customer_detail.customer_id = id;
      this.$emit("customerDetails", this.customer_detail);
      this.closeList();
    },
    showList() {
      const app = this.$f7;
      app.popover.open(".popover-menu");
    },
    closeList() {
      const app = this.$f7;
      app.popover.close(".popover-menu");
    },
    clearValue() {
      this.keyword = "";
      this.customer_name = "";
      this.customer_id = "";
      this.customer = "";
    },
  },
  watch: {
    customer_details: function (val) {
      this.$emit("customerDetails", this.customer_detail);
    },
  },
};
</script>
<style>
.popover-menu {
  margin-top: 170px;
}
</style>

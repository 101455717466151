<template>
  <div>
    <f7-list>
      <f7-list-input
        label="Requested By:"
        class="searchbar-demo tapHold"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keyup.native="searchUsers($event), showList()"
        @keydown.native="isTab($event)"
        @dblclick.native="clearValue()"
        @tapHold.native="clearValue()"
      ></f7-list-input>
    </f7-list>
    <f7-popover class="popover-menu">
      <f7-list>
        <f7-list-item
          v-for="user in users"
          :key="user.id"
          :value="user.full_name"
          @click="selectUser(user.id, user.full_name)"
          >{{ user.full_name }}</f7-list-item
        >
      </f7-list>
    </f7-popover>
  </div>
</template>
  
  <script>
export default {
  props: {
    defaultUser:[Number,String],
  },
  data() {
    return {
      user_id:0,
      keyword: "",
      showall: true,
      fieldname: "user",
      users: [],
      user_name: "",
      user: "",
      user_detail: {
        user: "",
        user_id: "",
      },
      shop: "",
      first_name: "",
      last_name: "",
      show_all: "",
      limit: 10,
      custsch: false,
    };
  },

  methods: {
    setDefaultUser(){
    this.user_id = this.defaultUser;
    
    if (this.user_id != 0) {
      const data = {
        id: this.user_id,
      };
      const url = apiUrl + "/api/users/user";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.user_name = response.data.full_name;
          this.keyword = response.data.full_name;
          this.user = response.data.full_name;
          this.user_id = response.data.id;
          this.user_detail.user = response.data.full_name;
          this.user_detail.user_id = response.data.id;
          this.$emit("userUpdate", this.user_detail);
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("false");
    }
  },
    isTab(e) {
      this.user = this.keyword;
      this.user_name = this.keyword;
      this.user_detail.user = this.keyword;
      this.user_detail.user_id = "";
      this.$emit("userDetails", this.user_detail);
    },
    searchUsers(e) {
      if (e.which == 13) {
        this.user = this.keyword;
        this.user_name = this.keyword;
        this.user_detail.user = this.keyword;
        this.user_detail.user_id = "";
        this.$emit("userDetails", this.user_detail);
      } else {
        this.user_name = this.keyword;

        const data = {
          keyword: this.keyword,
          limit: this.limit,
        };
        console.log(data);
        const url = apiUrl + "/api/users/search";

        axios
          .post(url, data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + TOKEN,
            },
          })
          .then((response) => {
            this.users = response.data;
            console.log(response);
            if (response.data.length < 1) {
              this.closeList();
            }

            console.log(response.data);
          })
          // .then((response) => (this.vacations = response.data.items))
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    selectUser(id, name) {
      // console.log(id, name);
      this.keyword = name;
      this.user_name = name;
      this.user_id = id;
      this.user = name;
      this.user_detail.user = name;
      this.user_detail.user_id = id;
      this.$emit("userUpdate", this.user_detail);
      this.closeList();
    },
    showList() {
      const app = this.$f7;
      app.popover.open(".popover-menu");
    },
    closeList() {
      const app = this.$f7;
      app.popover.close(".popover-menu");
    },
    clearValue() {
      this.keyword = "";
      this.user_name = "";
      this.user_id = "";
      this.user = "";
    },
  },
  watch: {
    user_details: function (val) {
      this.$emit("userUpdate", this.user_detail);
    },
    defaultUser: function (val){
      this.setDefaultUser();
    }
  },
};
</script>
  <style>
.popover-menu {
  margin-top: 170px;
}
</style>
  
<template>
  <f7-page >
  <f7-navbar title="Account Profile" back-link="Back"></f7-navbar>
    <f7-list>
      <f7-list-item header="First Name" :title=first_name>
      
        
         
      </f7-list-item>
      <f7-list-item  header="Last Name" :title=last_name>
       
      </f7-list-item>
    
      <f7-list-item  header="Eamil" :title=email>
       
      </f7-list-item>
      <f7-list-item  title="Logout" @click="logout"></f7-list-item>
    </f7-list>
    <f7-list>
 
      <f7-list-item link="/appsettings/" title="App Settings"></f7-list-item>
      </f7-list>
  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      first_name:"",
      last_name:"",
      email:"",
      shop:"",

    }
  },

  async created(){


   const userStore =  this.$vlf.createInstance({
              storeName: 'user'
          });
 await userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
 await userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("email")
      .then((value) => (this.email = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });   
      await userStore
      .getItem("shop")
      .then((value) => (this.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      }); 
  },

  methods:{
    logout(){
      
      this.$vlf.createInstance({storeName:'cred'}).clear().then(() => console.log("cleared Cred"));
      this.$vlf.createInstance({storeName:'user'}).clear().then(() => console.log("cleared User"));
 
      const router = this.$f7router;
      
     router.navigate('/');

    }
  }


}

  </script>

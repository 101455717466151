<template>
  <f7-page name="vacations">
    <f7-navbar title="Vacations" back-link="Back"><f7-link icon-f7="plus" class="right" href="/addvacations/"></f7-link></f7-navbar>

    <f7-list form>
      <f7-list-item v-for="vaca in vacations" v-bind:key="vaca.id" :link="link_var(vaca.id)" >
        <f7-block>
          <f7-block-header medium>{{vaca.summary}}</f7-block-header>
          <!-- <div class="text-align-center">{{vaca.summary}}</div> -->
          <f7-block-header class="text-align-center">Start: {{formatDate(vaca.start)}} {{formatTime(vaca.start) }}</f7-block-header>
          <f7-block-header class="text-align-center">End: {{formatDateEnd(vaca.end)}} {{formatTime(vaca.end) }}</f7-block-header>
        </f7-block>
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-block-footer v-if="noResults===true">
        <h3>You have no time off scheduled. Follow link below to add a new request</h3>
        <f7-list>
          <f7-list-item link="/addvacations/" title="Add Vacation"></f7-list-item>
        </f7-list>
      </f7-block-footer>
    </f7-list>
  </f7-page>
</template>

<script>
export default {
  data: function () {
    return {
      noResults: false,
      vacations: null,
      first_name: "",
      last_name: "",
      shop: "",
      start: "",
      end: "2040-01-01",
    };
  },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shop")
      .then((value) => (this.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    this.start = this.getDateStr();
    const data = {
      name: this.first_name + " " + this.last_name,
      start: this.start,
      end: this.end,
      shop: this.shop,
    };
    console.log(data);

    const url =
      "https://api.truckserviceco.com/calendar/recall-event/index.php";

    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => (this.vacations = response.data.items))
      .catch(function (error) {
        console.log(error);
      });

    if (this.vacations.length == 0) {
      this.noResults = true;
    }

    this.$f7ready((f7) => {});
  },

  methods: {
    link_var: function (str) {

      return "/editvacation/eventid/" + str + "/shop/" + this.shop;
    },

    getDateStr() {
      let s = new Date();

      return this.$moment(s).format("YYYY-MM-DD");
    },

    formatDate(dt) {
      let date = "";
      if (dt.dateTime !== null) {
        date = dt.dateTime;
      } else {
        date = dt.date;
      }

      return this.$moment(date).format("MM-DD-YYYY");
    },
    
    formatDateEnd(dt) {
      let date = "";
      if (dt.dateTime !== null) {
        date = dt.dateTime;
      } else {
        date = this.$moment(dt.date, "YYYY-MM-DD").subtract(1, "days");
      }

      return this.$moment(date).format("MM-DD-YYYY");
    },

    formatTime(tm) {
      if (tm.dateTime !== null) {
        return this.$moment(tm.dateTime).format("hh:mm A");
      }
    },
  },
};
</script>

<template>
  <f7-page name="Device">
    <f7-navbar title="Device View" back-link="Back"></f7-navbar>

    <f7-row class="details">
      <f7-badge
        class="badge shop_bold"
        :class="removeSpace(device._current_location.value)"
        >@{{ device._current_location.value }}</f7-badge
      >

      <f7-badge class="badge" color="blue">Diagnostic Computer</f7-badge>
    </f7-row>

    <f7-list media-list dividers-ios strong-ios outline-ios>
      <f7-list-item title="Device Name" :subtitle="device.name"> </f7-list-item>
      <f7-list-item
        v-if="device.reference"
        title="Reference No"
        :subtitle="device.reference"
      >
      </f7-list-item>
      <f7-list-item title="Model" :subtitle="device.model"> </f7-list-item>
      <f7-list-item title="Description" :text="device.description">
      </f7-list-item>
      <f7-list-item title="Software">
        <div
          v-for="software in device.softwares"
          :key="software.id"
          :title="software.name"
        >
          {{ software.name }}
        </div>
      </f7-list-item>

      <f7-list-item title="Home Shop">
        <f7-badge
          class="badge"
          :class="removeSpace(device._location.value)"
          color="green"
          >{{ device._location.value }}</f7-badge
        >
      </f7-list-item>
      <f7-list-item>
        <f7-button
          v-if="mShop == false && transfer == null"
          color="green"
          raised
          fill
          @click="deviceRequest('transfer')"
          >Request Transfer</f7-button
        >
        <f7-button
          v-if="device.location_id != device.current_location && mShop == true && transfer == null"
          color="red"
          raised
          fill
          @click="deviceRequest('return')"
          >Return Tool</f7-button
        >
        <br />
        <f7-button v-if="mShop == true" color="green" raised outline
          >Item Here</f7-button
        >
        <br />
        <f7-button
          v-if="mTransfer == true || mReturn == true"
          color="red"
          raised
          fill
          @click="deviceRequest('cancel')"
          >Cancel</f7-button
        >
      </f7-list-item>

      <f7-list-item v-if="mTransfer == true" class="transfer_alert">
        {{ transfer.user.full_name }} has requested this item be transfered from
        {{ device._current_location.value }} to {{ transfer._location.value }}.
      </f7-list-item>
      <f7-list-item v-if="mReturn == true" class="transfer_alert">
        {{ transfer.user.full_name }} has requested this item be returned from
        {{ device._current_location.value }} to {{ transfer._location.value }}.
      </f7-list-item>
    </f7-list>

    <div class="upper-area"></div>

    <f7-toolbar bottom class="bottom-toolbar">
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>

      <!-- <f7-link v-on:click="upload_file"
            ><i class="icon f7-icons color-blue">square_arrow_up</i></f7-link
          > -->
      <f7-link v-on:click="toggleNote"
        ><i class="icon f7-icons color-blue">chat_bubble_text</i></f7-link
      >
      <f7-link popup-open=".cam-popup"
        ><i class="icon f7-icons color-blue">camera</i></f7-link
      >
    </f7-toolbar>
  </f7-page>
</template>
  <script>
import Notes from "../../components/Notes.vue";
import Camera from "../../components/CameraComp.vue";

export default {
  components: {
    Notes,
    Camera,
  },
  data() {
    return {
      componentKey: 0,
      model: "Tools",
      isEditing: false,
      id: "",
      addTimes: false,
      shop: "cov",
      email: "",
      tz: "-05:00",
      device: {
        _location: {
          value: "",
        },
        _current_location: {
          value: "",
        },
      },
      notes: [],
      pics: "",
      picCount: "",
      noteCount: "",
      transfer: null,
      mShop: false,
      mTransfer: false,
      mReturn: false,
      user: {
        first_name: "",
        last_name: "",
        id: 0,
        shop: "",
        token: "",
        shopName: "",
      },
      domain: apiUrl,
      fileUrl: "/webroot/files/Tools/pics/",
    };
  },

  mounted() {
    this.id = this.$f7route.params.id;
    this.shop = this.$f7route.params.shop;

    this.domain = apiUrl;
  },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.user.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.user.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shop")
      .then((value) => (this.user.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("token")
      .then((value) => (this.user.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shopName")
      .then((value) => (this.user.shopName = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    const url = apiUrl + "/api/devices/view";

    const data = {
      id: this.$f7route.params.id,
    };

    await axios
      .post(url, data, {
        origin: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        console.log(response);
        this.notes = response.data.device.notes;
        this.device = response.data.device;
        this.transfer = response.data.transfer;
        this.transfer_status = this.setTransfer();
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(this.transfer);
    console.log(this.user);
  },

  methods: {
    reloadTool() {
      const url = apiUrl + "/api/devices/view";
      const data = {
        id: this.device.id,
      };
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.notes = response.data.device.notes;
          this.device = response.data.device;

          this.transfer = response.data.transfer;
          this.transfer_status = this.setTransfer();
          console.log(this.transfer);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    opencam() {
      const app = this.$f7;
      app.popup.open("#camera");
    },
    setTransfer() {
      this.mTransfer = this.myTransfer();
      this.mReturn = this.myReturn();
      this.mShop = this.myShop();
    },
    myTransfer() {
      if (
        this.transfer != null &&
        this.user.shopName === this.transfer._location.value
      ) {
        return true;
      } else {
        return false;
      }
    },
    myReturn() {
      if (
        this.transfer != null &&
        this.user.shopName === this.device._current_location.value
      ) {
        return true;
      } else {
        return false;
      }
    },
    myShop() {
      if (this.device._current_location.value === this.user.shopName) {
        return true;
      } else {
        return false;
      }
    },
    toggleNote() {
      alert("add note");
    },
    setPics(pics) {
      this.pics = pics;
      this.picCount = this.setBadge(pics.length);
    },
    setNoteCount(noteCount) {
      console.log(noteCount);
      this.noteCount = this.setBadge(noteCount);
    },
    setNotes(notes) {
      console.log(notes);
      this.notes = notes;
    },
    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    formatTitle(val) {
      val = val.split("_");
      return val[2];
    },
    deletePic(pic) {
      var del = confirm("Delete this image?");

      if (del == true) {
        this.sendDeleteRequest(pic);
      }
    },

    deviceRequest(request) {
      var api = "";
      if (request === "transfer" || request === "return") {
        let special = prompt("Special Instructions:");
        api = "/api/devices/requesttransfer";
        var data = {
          id: this.device.id,
          current_branch: this.device._current_location.value,
          home_branch: this.device.location_id,
          special: special,
          request: request,
        };
      } else if (request == "cancel") {
        api = "/api/devices/canceltransfer";
        var data = {
          id: this.transfer.id,
        };
      } else {
        alert("Something went wrong. Try your request again.");
        return;
      }
      const url = this.domain + api;

      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.reloadTool();
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendDeleteRequest(filename) {
      const data = {
        filename: filename,
      };
      console.log(data);
      const url = apiUrl + "/api/tools/deletedoc";

      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response);
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
  <style>
.view {
  border-color: transparent;
  background-color: initial;
  color: initial;
}
.tool_img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  float: left;
}
.transfer_alert {
  background-color: yellow;
}
.space {
  padding-bottom: 100px;
}
</style>
<template>
  <f7-page name="tool">
    <f7-navbar 
      >
      <f7-nav-left>
        <f7-link icon-ios="f7:chevron_left" icon-md="material:chevron_left"  href="/partscounter/">Back</f7-link>
      </f7-nav-left>
      <f7-nav-title>
        Parts Counter Request
      </f7-nav-title>
</f7-navbar>
    <f7-list media-list dividers-ios strong-ios outline-ios>
      <f7-list-item v-if="partscounter._urgency.value" :class="removeSpace(partscounter._urgency.value)">{{ partscounter._urgency.value }}</f7-list-item>
      <f7-list-item>
      <f7-row class="details">
        <f7-badge class="badge" color="blue"
          >Requested By: {{ partscounter._created_by.full_name }}
          {{ formatTime(partscounter.created) }}</f7-badge
        >
      </f7-row>
      <f7-row class="details">
        <f7-badge
          class="badge shop_bold"
          :class="removeSpace(partscounter._branch.value)"
          >Shop: {{ partscounter._branch.value }}</f7-badge
        >
      </f7-row>
      <f7-row class="details"  v-if="partscounter._assigned_to">
        <f7-badge
          v-if="partscounter._assigned_to"
          class="badge shop_bold"
          color=""
          >Assigned To: {{ partscounter._assigned_to.full_name }}
          {{ formatTime(partscounter.assigned) }}</f7-badge
        >
      </f7-row>
        <f7-row class="details">
        <f7-badge
          v-if="partscounter._status"
          :class="'bg-' + partscounter._status.color_code + ' badge shop_bold'"
          
          >Current Status: {{ partscounter._status.value }}
        </f7-badge
        >
      </f7-row>
    </f7-list-item>
      <f7-list-item
        v-if="partscounter.id"
        title="Customer"
        :subtitle="partscounter.customer"
      >
      </f7-list-item>
    
      </f7-list>
   
      <f7-list>
        <f7-list-item>
        <div class="card data-table">
        <table>
          <thead>
            <tr>
              <th>Part No</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key, index) in partscounter.part_numbers" :key="key">
<td>{{value.partno }}</td>
<td>{{value.qty }}</td>
</tr>
          </tbody>
        </table>
     
        <f7-button class="order" fill v-if="array_length(partscounter.part_numbers) > 0 && partscounter._status.value != 'Order'" 
          @click="order('Order')">Order</f7-button>
          <f7-button class="cancel" fill v-if="array_length(partscounter.part_numbers) > 0 && partscounter._status.value == 'Order'" 
          @click="order('Cancel Order')">Cancel Order</f7-button>
        </div>
      </f7-list-item>
      </f7-list>
      <f7-list media-list dividers-ios strong-ios outline-ios>
      <f7-list-item title="Vehicle Details">
        <div v-for="(value, key, index) in partscounter.vehicle" :key="key">
          <span v-if="value !=''">
          <span class="vh_key">{{ key.toUpperCase() }}:</span
          ><span class="vh_val">{{ value }}</span>
        </span>
        </div>
      </f7-list-item>
      <f7-list-item v-if="partscounter.details != ''" title="Details">
        <span class="vh_val">{{ partscounter.details }}</span>
      </f7-list-item>
      <f7-list-item
        v-if="partscounter.special_instructions != ''"
        title="Special Instructions"
      >
        <span class="vh_val">{{ partscounter.special_instructions }}</span>
      </f7-list-item>
      

    </f7-list>

    <div class="upper-area">
      <f7-list accordion-list inset>
        <f7-list-item
          id="note"
          accordion-item
          title="Notes"
          :badge="noteCount"
          badge-color="red"
        >
          <f7-accordion-content id="note">
            <Notes
              :model="model"
              :notes="notes"
              :userid="user.id"
              :entid="partscounter.id"
              @notesCount="setNoteCount"
              @updateNotes="setNotes"
            />
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item
          accordion-item
          title="Documents"
          :badge="setBadge(docCount)"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
              v-if="docs.length > 0"
              pagination
              navigation
              scrollbar
              :speed="500"
              :slidesPerView="3"
              :spaceBetween="20"
            >
              <f7-swiper-slide
                v-for="(doc, index) in docs"
                :key="index"
                @taphold.native="deleteDoc(doc)"
              >
                <div
                  class="tapHold"
                  style="padding: 10px"
                  @dblclick="deleteDoc(doc)"
                >
                  <iframe
                    type="application/pdf"
                    :src="domain + fileUrl + doc + '\#zoom=FitW'"
                  ></iframe>
                </div>
              </f7-swiper-slide>

              <f7-swiper-slide
                ><div v-on:click="upload_file">
                  <div>Add File...</div>
                  <i class="icon f7-icons color-blue slider_add"
                    >square_arrow_up</i
                  >
                </div></f7-swiper-slide
              >
            </f7-swiper>

            <f7-block v-else-if="docs.length == 0">
              <div v-on:click="upload_file" class="upload_icon">
                <div>Add File...</div>
                <i class="icon f7-icons color-blue slider_add"
                  >square_arrow_up</i
                >
              </div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item
          accordion-item
          title="Pictures"
          :badge="picCount"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
              v-if="pics.length > 0"
              pagination
              navigation
              scrollbar
              :loop="false"
              :speed="500"
              :slidesPerView="1"
              :spaceBetween="20"
            >
              <f7-swiper-slide
                v-for="(pic, index) in pics"
                :key="index"
                :title="pic"
                @taphold.native="deletePic(pic)"
              >
                <figure>
                  <figcaption>{{ formatTitle(pic) }}</figcaption>
                  <img
                    class="tapHold"
                    :src="domain + fileUrl + pic"
                    @dblclick="deletePic(pic)"
                  />
                </figure>
              </f7-swiper-slide>
              <f7-swiper-slide>
                <div v-on:click="opencam">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div>
              </f7-swiper-slide>
            </f7-swiper>

            <f7-block v-else-if="pics.length == 0" class="upload_icon">
              <div v-on:click="opencam">
                <div>Add image...</div>
                <i class="icon f7-icons color-blue slider_add">camera</i>
              </div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <div style="height: 40px"></div>
      <f7-popup id="camera" class="cam-popup">
        <f7-page>
          <f7-navbar title="Upload Pictures">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <Camera
            :model="model"
            :item="partscounter"
            :uid="user.id"
            @picList="setPics"
          />
        </f7-page>
      </f7-popup>


      <f7-popup id="fileloader" class="file-popup">
        <f7-page>
          <f7-navbar title="Upload Files">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <FileLoader
            :item="partscounter"
            :model="model"
            :uid="user.id"
            @picList="setPics"
            @docList="setDocs"
          />
        </f7-page>
      </f7-popup>

    </div>

    <f7-toolbar bottom class="bottom-toolbar">
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>
      <f7-link v-on:click="toggleNote"
        ><i class="icon f7-icons color-blue">chat_bubble_text</i></f7-link
      >
      <f7-link popup-open=".cam-popup"
        ><i class="icon f7-icons color-blue">camera</i></f7-link
      >
    </f7-toolbar>
  </f7-page>
</template>
  <script>
import Notes from "../../components/Notes.vue";
import Camera from "../../components/CameraComp.vue";
import FileLoader from "../../components/FileLoader.vue";


export default {
  components: {
    Notes,
    Camera,
    FileLoader,
  },
  data() {
    return {
      partscounter: {
        part_numbers:{},
        _branch: {
          value: "",
        },
        _created_by: {
          full_name: "",
        },
        _status: {
          value: "",
        },
        _urgency: {
          value:"",
        }

      },
      componentKey: 0,
      model: "Partscounters",
      isEditing: false,
      id: "",
      addTimes: false,
      shop: "cov",
      email: "",
      tz: "-05:00",
      notes: [],
      pics: "",
      picCount: "",
      docs: "",
      docCount: "",
      noteCount: "",
      user: {
        first_name: "",
        last_name: "",
        id: 0,
        shop: "",
        token: "",
        shopName: "",
      },
      domain: apiUrl,
      fileUrl: "/webroot/files/Partscounters/pics/",
    };
  },

  mounted() {
    this.id = this.$f7route.params.id;
    this.shop = this.$f7route.params.shop;

    this.domain = apiUrl;
  },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.user.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.user.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shop")
      .then((value) => (this.user.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("token")
      .then((value) => (this.user.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shopName")
      .then((value) => (this.user.shopName = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    const url = apiUrl + "/api/partscounters/request";

    const data = {
      id: this.$f7route.params.id,
    };

    await axios
      .post(url, data, {
        origin: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.partscounter = response.data.partscounter;
        this.notes = response.data.partscounter.notes;
        this.noteCount = this.setBadge(response.data.partscounter.notes.length);
        this.pics = response.data.pics;
        this.picCount = this.setBadge(response.data.pics.length);
        this.docs = response.data.docs;
        this.docCount = this.setBadge(response.data.docs.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    reloadTool() {
      const url = apiUrl + "/api/partscounters/request";
      const data = {
        id: this.tool.id,
      };
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.notes = response.data.tool.notes;
          this.tool = response.data.tool;
          this.noteCount = this.setBadge(response.data.notes.length);
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    removeSpace(val) {
     
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    opencam() {
          const app = this.$f7;
          app.popup.open("#camera");
    },

    toggleNote() {
      alert("add note");
    },
    setPics(pics) {
      this.pics = pics;
      this.picCount = this.setBadge(pics.length);
    },
    upload_file() {
      const app = this.$f7;
      app.popup.open("#fileloader");
    },
    setNoteCount(noteCount) {
      console.log(noteCount);
      this.noteCount = this.setBadge(noteCount);
    },
    setNotes(notes) {
      console.log(notes);
      this.notes = notes;
    },
    setDocs(docs) {
      this.docs = docs;
      this.docCount = this.setBadge(docs.length);
    },
    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    formatTitle(val) {
     
      val = val.split("_");
      return val[2];
    },
    deletePic(pic) {
      var del = confirm("Delete this image?");

      if (del == true) {
        this.sendDeleteRequest(pic);
      }
    },

    sendDeleteRequest(filename) {
      const data = {
        filename: filename,
      };
      console.log(data);
      const url = apiUrl + "/api/partscounters/deletedoc";
      console.log(url);
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response);
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    ucaseWords(val) {
      return val.toUpperCase();
    },
    array_length(val){

return val.length;
    },
    formatTime(time) {
      if (time != " ") {
        var time = new Date(time);

        return " @ " + this.$moment(time).format("MM-DD-YYYY h:mm A");
      } else {
        return;
      }
    },
    order(val){
      const router = this.$f7router;
      const data = {
        id:this.partscounter.id,
        status:val,
      };
      const url = apiUrl + "/api/partscounters/updatestatus";
 
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          
          console.log(response);
          router.navigate("/partscounter/");
        })
        .catch(function (error) {
          console.log(error);
        });    }
  },
};
</script>
  <style>
.view {
  border-color: transparent;
  background-color: initial;
  color: initial;
}
.tool_img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  float: left;
}
.transfer_alert {
  background-color: yellow;
}
.space {
  padding-bottom: 100px;
}
.vh_key {
  margin-right: 10px;
  font-size: 0.8em;
}
.vh_val {
  font-size: 0.8em;
}
.cancel{
  background-color:red;
}
</style>
<template>
  <f7-list>
    <f7-list-item
      :title="label"
      ref="list"
      :class="type"
      smart-select
      :smart-select-params="{
        openIn: 'popup',
        searchbar: true,
        searchbarPlaceholder: 'Search',
      }"
    >
      <select :name="type" ref="item" @change="closeList()" >
        <option
          v-for="item in list"
          :id="item.id"
          :key="item.id"
          :value="item.id"
          @click="setValue(item.id, item.value)"
        >
          {{ item.value }} 
        </option>
      </select>
    </f7-list-item>
  </f7-list>
</template>
  <style>
button {
  height: 50px;
}
</style>
  <script>
export default {
  props: {
    defaultValue: [Number, String,Array],
    type: String,
    label: String,
    except: Array,
  },
  data() {
    return {
    //   title: "List",
      list: {},
      id: "",
      details:{
        field:"",
        value:""
      }
     
    };
  },
  async created() {
    
    this.details.field = this.type;
    const data = {
        menu: this.type,
      except: this.except,
    };

    const url = apiUrl + "/api/dropdowns/dropdownlist";

    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        console.log(response)
        this.list = response.data;
  
      })
      .finally(() => {
        this.$refs.list.f7SmartSelect.setValue(this.defaultValue);
        console.log(this.defaultValue);
        console.log('**')
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    selected(action, id) {
      if (action == id) {
        return true;
      } else {
        return false;
      }
    },
    closeList() {
        this.details.value = this.$refs.item.value;
   
      this.$emit("valueUpdated", this.details);
      // this.$refs.list.f7SmartSelect.setValue(id);
      this.$refs.list.f7SmartSelect.close();
    },
    alt() {
      // alert(this.defaultBranch)
    },
    setActionClass(val) {
      return val.toLowerCase().replace(" ", "_");
    },
  },
  watch: {
    list: function (val) {
    this.details.value = val;
      this.$emit("valueUpdated", this.details);
    },
    defaultValue: function (val) {
      if (val != "" && val != this.details.value) { 
        this.$refs.list.f7SmartSelect.setValue(val);
        this.details.value = val;
      }
    },
  },
};
</script>
  
<template>
  <f7-page name="addtask">
    <f7-navbar :title="'Add ' + request_type" back-link="Back"></f7-navbar>
    <f7-list>
      <f7-list-item>
      <f7-list-input
        label="Request"
        type="text"
        placeholder="Request..."
        clear-button
        :value="task.name"
        @input="task.name = $event.target.value"
      />
    </f7-list-item> 
      <f7-list-item>
      <f7-list-input
        label="Request Description"
        type="textarea"
        placeholder="Description"
        clear-button
        :value="task.description"
        @input="(task.description = $event.target.value)"
      />
    </f7-list-item> 
      <ShopList
        :defaultBranch = "branch"
        @branchUpdated="updateBranch"
        />
        </f7-list>
        <f7-list>

<f7-list-item v-if="addImageShow" class="input_container" title="Add Image">
      <f7-input
        id="doc"
        type="file"
        placeholder="Image"
        clear-button
        @input="task.doc = $event.target.files[0]"
        @change="swapInputs()"
      />
    </f7-list-item>
      <f7-list-input
        v-if="addTempName"
        label="Name Image"
        ref="tempname"
        type="text"
        placeholder="Name Image"
        clear-button
        @dblclick.native="changeImage()"
        :value="task.tempname"
        @input="task.tempname = $event.target.value"
        @taphold.native="changeImage()"
      />

      <f7-block strong>
        <f7-row>
          <f7-col>
            <f7-button fill color="red" @click="$f7router.back()"
              >Cancel</f7-button
            >
          </f7-col>
          <f7-col>
            <f7-button fill color="green" @click.prevent="addTask"
              >Submit Request</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block>
      <!-- <img :src="imgUrl" :alt="imgUrl" /> -->
    </f7-list>
  </f7-page>
</template>
    <script>
import UserSearch from "../../components/UserSearch.vue";
// import DepartmentList from "../../components/DepartmentList.vue";
import ShopList from "../../components/ShopList.vue";

export default {
  components: {
    UserSearch,
    // DepartmentList,
    ShopList,
  },

  data() {
    return {
      addImageShow: true,
      addTempName: false,
      uid: "",
      branch: 0,
      user_id: "",
      token: "",
      type: "",
      request_type:"",
      task: {
        name: "",
        description: "",
        branch: "",
        created_by: "",
        modified_by: "",
        doc:"",
        tempname:""
      },
      imgUrl:""
    };
  },
  async mounted() {
    this.type = this.$f7route.params.type;
    if(this.type == 'tool'){
      this.request_type = 'Tool Needed Request';
    }else{
      this.request_type = 'Maintenance Request';
    }
    var user = this.$vlf.createInstance({
      storeName: "user",
    });
    var credStore = this.$vlf.createInstance({
      storeName: "cred",
    });

    user
      .getItem("id")
      .then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

      user
      .getItem("branch")
      .then((value) => (this.branch = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    this.task.due = this.setDefaultDate();
  },
  methods: {
    setDefaultDate() {
      let d = new Date();

      return this.$moment(d).format("YYYY-MM-DD");
    },

    updateBranch(val) {
      this.task.branch = val;
      console.log("branch:" + this.task.branch);
    },
    updateDepartment(val) {
      this.task.department = val;
      console.log("department:" + this.task.department);
    },
    updateAssigned(val) {
      this.task.assigned_to = val;
      console.log("assigned:" + this.task.assigned_to);
    },

    addTask() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      this.task.created_by = this.uid;
      this.task.modified_by = this.uid;
    
      
  
      const fd = new FormData();

      if(this.task.doc !=''){
      fd.append('doc', this.task.doc,this.task.tempname);
      }
      fd.append('name',this.task.name);
      fd.append('description',this.task.description);
      fd.append('branch',this.task.branch);
      fd.append('created_by',this.uid);
      fd.append('modified_by',this.uid);
      fd.append('tempname',this.task.tempname);
      fd.append('department',this.type);


      const url = apiUrl + "/api/tasks/maintenance";
console.log(fd)
      axios
        .post(url, fd, {
          headers: {
            Accept: "application/json", //This needs to be removed to upload files
            "Content-Type": "application/json", //This needs to be removed to upload files
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          router.back({
            force: true,
            ignoreCache: true,
          });
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    swapInputs() {
    
     this.addTempName = true;
     this.addImageShow = false;
     this.task.tempname ='';
     this.imgUrl = this.fileUrl();
    },
    changeImage(){
      this.addTempName = false;
     this.addImageShow = true;
     this.task.doc = {};
     this.task.tempname ='';
    },

    fileUrl(){
  console.log(this.task.doc)
      if(typeof this.task.doc === 'object'){
   this.imgUrl =  URL.createObjectURL(this.task.doc);
console.log(this.imgUrl)
      }else{
    this.imgUrl = '';
    console.log('none')
      }
    }
  },
};
</script>
  <style>
.input_container {
  border: 1px solid #e5e5e5;
}

input[type=file]::file-selector-button {
  background-color: #fff;
  color: #000;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  padding: 10px 15px;
  margin-right: 20px;
  transition: .5s;
}

input[type=file]::file-selector-button:hover {
  background-color: #eee;
  border: 0px;
  border-right: 1px solid #e5e5e5;
}
</style>
<style>
.border_red {
    border:2px solid red;
}
.warn{
    color:red;
}

</style>

<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
    <f7-login-screen-title>Servco App</f7-login-screen-title>
     <f7-list form>
       
      <f7-list-input
        label="New Password"
        type="password"
        placeholder="Enter New Password"
        :value="password"
        @input="password = $event.target.value"
        autocomplete="new-password"
      ></f7-list-input>
      <f7-list-input
        v-bind:class=check()
        v-on:blur="validate"
        label="Re-enter Password"
        type="password"
        placeholder="Re-enter password"
        :value="match"
        @keypress.native.enter ="updatePassword"
        @input="match = $event.target.value"
      ></f7-list-input>
      <f7-label class="warn"  v-if= "update === false">Passwords do not match. Please re-enter.</f7-label>
     </f7-list>

        <f7-list>
    
      <f7-list-button @click="updatePassword">Update Password</f7-list-button>

      <f7-block-footer>Enter and confirm new password</f7-block-footer>
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        id: '',
        password: '',
        match: '',
        update: '',
        updated:'',
      };
    },

    mounted(){
      this.id = this.$f7route.params.id;
      this.passkey = this.$f7route.params.passkey;
    },
   
    methods: {

       updatePassword() {
    

         if(this.update === true){
        const url = apiUrl + "/api/users/resetpassword";
    //  const url = "https://api.truckserviceco.com/login/update.php";
        const self = this;
        const app = self.$f7;
        const router = self.$f7router;
        const pwd = self.password;
       const passkey = self.passkey;

       const dt =  {password: pwd,id:this.id,passkey:passkey};
    
 try {
  axios.post(url, dt, {
    headers: {
   'Accept': 'application/json',
   'Content-Type':'application/json'}
}).then(response =>this.updated = response.data)
                .then(function(updated){
                    console.log(updated)
              if(updated.status == 'success'){
                  app.dialog.alert(`Your password has been updated. You will be redirected to the login page.`, () => {
          router.navigate('/login/');
                  });
                  }else{
                      app.dialog.alert(`Something went wrong. Try resetting your password again.`, () => {
                    
                  });
                  }
                  
                })
                  .catch(function(error){

                    self.reseterror = true;
             
                  console.log(error);
               
              
                  });


   } catch (error) {
  
     console.error(`Request error: ${error.message}`);
      
   }
         
         }
       
       
      },



        validate(){
        
        if(this.password === this.match){
            this.update = true; 
        }else{
            this.update = false;
        }
    },
    check(){
        if(this.update === false){
            return "border_red";
        }else{
            return "";
        }
    },
    }

      
  };
</script>
<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
    <f7-login-screen-title>Servco App</f7-login-screen-title>
    <f7-list form>
      <f7-list-input
        label="Email"
        type="email"
        validate
         :onValidate="(isValid) => setInputValid(isValid)"
        placeholder="Your email address"
        :value="username"
        @input="username = $event.target.value"
      ></f7-list-input>

    </f7-list>
    <f7-list>
      <f7-list-button @click="resetPassword">Reset Password</f7-list-button>

      <f7-block-footer>Enter your email address to reset your password.</f7-block-footer>
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        username: '',
        reset: '',
      
      };
    },
   
    methods: {

      resetPassword() {
        const url = apiUrl + "/api/users/sendResetEmail";
            //  const url = "https://api.truckserviceco.com/login/reset.php";
        const self = this;
        const app = self.$f7;
        const router = self.$f7router;
        const un = self.username;
        const host = window.location.hostname;
 
       const dt =  {username: un, host:host };

 try {
  axios.post(url, dt, {headers: {
   'Accept': 'application/json',
   'Content-Type':'application/json'}
}).then(response =>this.reset = response.data)
                .then(function(reset){
              
                  app.dialog.alert(`A password reset email has been sent to <strong>${self.username}</strong>. If you don't receive this email either you entered an incorrect email address or it went to your spam folder.`, () => {
          router.navigate('/login/');
        });
                  
                })
                  .catch(function(error){

                    self.reseterror = true;
             
                  console.log(error);
               
              
                  });


   } catch (error) {
  
     console.error(`Request error: ${error.message}`);
      
   }

        
      },
    },
  };
</script>

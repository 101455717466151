<template>
  <f7-page name="form">
    <f7-navbar title="BigCommerce Item" back-link="Back"></f7-navbar>

    <f7-block-title>Add Item To BigCommerce</f7-block-title>
    <f7-list no-hairlines-md>
      <f7-list-input
        label="Name"
        type="text"
        :value="form.name"
          @input="form.name=$event.target.value"
        >{{form.name}}
     
       </f7-list-input>

      <f7-list-input
        type="textarea"
        label="Description"
        placeholder="Description"
        :value="form.description"
          @input="form.description=$event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Interchange Numbers"
        type="text"
        placeholder="Interchange Numbers"
        :value="form.interchange"
          @input="form.interchange=$event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Page Title"
        type="text"
        placeholder="Page Title"
        :value="form.page_title"
          @input="form.page_title=$event.target.value"
      ></f7-list-input>

      <f7-list-input
        label="Meta Keywords"
        type="text"
        placeholder="Meta Keywords"
        :value="form.meta_keywords"
          @input="form.meta_keywords=$event.target.value"
      ></f7-list-input>

      <f7-list-input
        type="textarea"
        label="Meta Description"
        placeholder="Meta Description"
        :value="form.meta_description"
          @input="form.meta_description=$event.target.value"
      ></f7-list-input>

      <f7-list-input
        type="text"
        label="Vendor Part Number"
        :value="form.mpn"
          @input="form.mpn=$event.target.value"
          ></f7-list-input>         
    

   
  
<!-- ****************************** -->
    <!-- <f7-list-item>
     <f7-block-title>Select Categories</f7-block-title>
            </f7-list-item>  -->
 <f7-list-item >
 <f7-block-title></f7-block-title>
  <f7-block strong class="no-padding-horizontal">
    <f7-treeview>
      <f7-treeview-item label="Select Categories">
      <f7-treeview-item item-toggle v-for="category in categories"  :key="category.id" :label="category.name"  >
        <template #content-start>
<!-- https://framework7.io/vue/treeview.html -->
          <f7-checkbox 
           :checked="cat_status[formatcat(category.name)].status"
            @change="cat_status[formatcat(category.name)].status = $event.target.checked, test()"
          />

        </template>
        <f7-treeview-item v-for="child in category.children"  :key="child.id" :label="child.name" >
          <template #content-start>
           
            <f7-checkbox
              :checked="cat_status[formatcat(category.name)][formatcat(child.name)].status"
              @change="cat_status[formatcat(category.name)][formatcat(child.name)].status = $event.target.checked, test()"
            />
          </template>
        </f7-treeview-item>
      


        </f7-treeview-item>
      </f7-treeview-item>


      
      <!-- </f7-treeview-item> -->
    </f7-treeview>
  </f7-block>
 </f7-list-item>
<!-- *************************************************** -->
            <f7-list-item>
     <f7-block-title>Set Options</f7-block-title>
            </f7-list-item> 
     <f7-list-item title="Truck Make" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search truck make'}">
      <select name="truck">
           <option value="" >N/A</option>
          <option value="peterbilt">Peterbilt</option>
          <option value="volvo">Volvo</option>
          <option value="kenworth">Kenworth</option>
          <option value="cat" >Cat</option>
        
        </select>
 </f7-list-item>
<f7-list-item title="Engine Make" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search engine make'}">
      <select name="engine">
           <option value="" >N/A</option>
          <option value="peterbilt">Peterbilt</option>
          <option value="volvo">Volvo</option>
          <option value="kenworth">Kenworth</option>
          <option value="cat" >Cat</option>
        
        </select>
 </f7-list-item>
 <f7-list-item title="Transmission Make" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search transmission make'}">
      <select name="transmission">
           <option value="" >N/A</option>
          <option value="peterbilt">Peterbilt</option>
          <option value="volvo">Volvo</option>
          <option value="kenworth">Kenworth</option>
          <option value="cat" >Cat</option>
        
        </select>
 </f7-list-item>
 <f7-list-item title="Condition" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search condition'}">
      <select name="condition">
           <option value="" >N/A</option>
          <option value="peterbilt">New</option>
          <option value="volvo">Used</option>
        
        </select>
 </f7-list-item>
 <f7-list-item>
     <f7-block-title></f7-block-title>
            </f7-list-item> 
 <f7-list-input
        type="text"
        label="Weight(oz)"
        placeholder="Weight(oz)"
      ></f7-list-input>
      <f7-list-input
        type="text"
        label="Price"
        placeholder="Price"
      ></f7-list-input>
      <f7-list-input
        type="text"
        label="Cost"
        placeholder="Cost"
      ></f7-list-input>
      <f7-list-item checkbox title="Available" name="is_available" checked></f7-list-item>
    <f7-list-item checkbox title="Visible" name="is_visible" ></f7-list-item>
<form id="imageForm" action="javascript:void(0)" enctype="multipart/form-data">
<input
        type="file"
        label="Pic"
        name="pic"
        id= "file"
        accept="image/*"
        ref="file"
        v-on:change="handleFileUpload()"
      >
      </form>
      
     
   
<img id="image" />

    
  

     </f7-list>
  </f7-page>
</template>

<script>
    export default {
        data(){
            return {
                token:'',
                file:'',
                vendors:'',
                categories:'',
                cat_status:[],
                form:{
                name:'',
                description:'',
                description_edit:'',
                interchange:'',
                page_title:'',
                meta_keywords:'',
                meta_description:'',
                mpn:'',
                brand_id:'',
                categories:[],
                weight:'',
                price:'',
                cost_price:'',
                availability:'',
                is_visible:''
                },
                options: {
                truck_make:'',
                engine_make:'',
                transmisson_make:'',
                condition:''
                },


            }

        },
        async created()  {

       const credStore =  this.$vlf.createInstance({
           storeName: 'cred'
       });

await credStore.getItem('token').then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

await credStore.getItem('uid').then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
        // this.loadData();

        this.loadVendors();
        },


        methods: {

            loadVendors(){

              axios.post(apiUrl + '/api/intraconnects/vendors?token='+ this.token, {headers: {
             'Accept': 'application/json',
            'Content-Type':'application/json',
            Authorization: "Bearer " + TOKEN,
          }
              }).then(response =>{
              // }) .then(response => {
                this.vendors = response.data.vendors;
                this.categories = response.data.categories;
                this.setcategories();
          //       console.log(this.cat_status)
                console.log(this.categories)
          // console.log(this.categories.children);
              }).catch(function(error){console.log(error)});
            },

            submitFile(){
// alert(this.form.name + this.form.mpn + ' ' + this.file.name )
               var formData = new FormData();
// alert(this.form.name);
                formData.append('file', this.file);
                formData.append('name', this.form.name);
                formData.append('mpn', this.form.mpn);
                // alert(fomData);

             axios.post(apiUrl + '/api/intraconnects/loadimage?token='+ this.token, formData,{
              headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + TOKEN,}
            }).then(function(){
            console.log('SUCCESS!!');
            })
            .catch(function(){
            console.log('FAILURE!!');
            });
            },

       handleFileUpload(){
    
        this.file = this.$refs.file.files[0];
console.log(this.$refs.file.files[0])
    
        this.submitFile();
      },

      categories_checkbox(cat){
        this.category.cat = false;
      },
      
      setcategories(){
        var c = this.categories;
        // console.log(c)
        c.forEach(cat => {
          var t = this.formatcat(cat.name);
          this.cat_status[t] =[];
          this.cat_status[t].status=false;
          this.cat_status[t].id = cat.id;
          var ch = cat.children;
          ch.forEach(child => {
            var cn = this.formatcat(child.name)
            var cs = this.cat_status[t][cn]= {
              status:false,
              id:child.id
            };
          
            // this.cat_status[t][cn].id = child.id;

          })
        })


      },
      formatcat(t){
   return t.toLowerCase().replace(/(?!\w|\s)./g, '')
    .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
    .replace(/\s+/g, '_');
      },

      test(){
   this.form.categories =[];
        var c = this.cat_status;
        console.log(c)
      Object.keys(c).forEach(key => {
        
     Object.keys(c[key]).forEach(test=>{
      //  console.log(c[key][test])
      if(c[key][test].status == true){
        c[key].status = true;
        this.form.categories.push(c[key][test].id);
      }
     
     })
 if(c[key].status == true){
         this.form.categories.push(c[key].id)
        }

      })
    
console.log(this.form.categories)
      }
    

            },
              mounted () {
                
      // console.log(this.$refs)   // prints 'Object {child: VueComponent}'
    }

        }


        
    

</script>


<template>
<f7-list>
 <f7-list-item :title="title" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search users'}">
      <select :name="fieldname" v-model = "user">

          <option   v-for="user in users" :key="user.id" :value="user.id"  >{{user.full_name}}</option>
          
    
    
      </select>
    </f7-list-item>
</f7-list>
</template>

<script>
export default {
  props:{
   fieldname:String,
   title:String,
   defaultUser:{ 
    type:Number, 
    default:0},
    }, 
    data(){
        return {
            users:[],
            user:'',
        };
        },

async created() {
    const data = {
      
    };

    const url = apiUrl + "/api/users/list";
    
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + TOKEN,
        },
      })
      .then((response) => {
      this.users = response.data
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  
    },
    watch: {
        user : function(val) {

        this.$emit('userUpdated', val)
    }
     }


};

</script>

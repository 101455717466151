<style>
.info {
  background-color: lightblue;
}
.success {
  background-color: lightgreen;
}
.warning {
  background-color: lightyellow;
}
.danger {
  background-color: lightpink;
}

.land {
  font-size: 0.8em;
}
.port {
  font-size: 0.6em;
}

.data-table th,
.data-table td {
  padding-right: 6px;
}

.th-sticky {
  position: sticky !important;
  top: 0px !important;
  z-index: 99 !important;
  box-shadow: 5px 1px #e0e0e0 !important;
}

/* .card, data-table{
  height:100%;
}  */
.hide {
  display: none;
}
.right {
  float: right;
}
.link-color {
  color: #007aff;
}

/* iPad landscape oriented styles */

@media only screen and (min-width: 480px) {
  .orient {
    font-size: 0.8em;
  }
  .orient-date {
    font-size: 0.8em;
  }
}

/*iPad Portrait oriented styles */

@media only screen and (max-width: 479px) {
  .orient {
    font-size: 0.6em;
  }
  .orient-date {
    font-size: 0.6em;
  }
  .show {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  .orient {
    font-size: 1em;
  }
  .orient-date {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .orient {
    font-size: 0.8em;
  }
  .orient-data {
    font-size: 0.8em;
  }
}
</style>
<template>
  <f7-page name="monthlytotals">
    <f7-navbar :title="header_title" back-link="Back"></f7-navbar>

    <div class="card">
      <form class="list" id="my-form">
        <ul>
          <f7-list-item
            class="link link-color"
            link=""
            title="View Monthly Eff Details"
            v-on:click="effDetails()"
            ><f7-icon icon="arrow-right" icon-color="blue"></f7-icon
          ></f7-list-item>
          <f7-list-item
            title="Year"
            smart-select
            :smart-select-params="{
              closeOnSelect: 'true',
              searchbar: true,
              searchbarPlaceholder: 'Search year',
            }"
          >
            <select name="year" v-on:change="setYear($event.target.value)">
              <option  v-for="yearA in yearArr"  :key="yearA" :value="yearA"  v-bind:selected = "selYear('yearA')" >{{yearA}}</option>
            </select>

          </f7-list-item>
          <f7-list-item
            title="Month"
            smart-select
            :smart-select-params="{
              closeOnSelect: 'true',
              searchbar: true,
              searchbarPlaceholder: 'Search month',
            }"
          >
            <select name="month" v-on:change="setMonth($event.target.value)">
              <option  v-for="(monthA, index) in monthArr"  :key="monthA" :value="(index + 1)" v-bind:selected = "selMon(index + 1)">{{monthA}}</option>

              <!-- <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option> -->
            </select>
          </f7-list-item>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Total Billed Hours</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.total_billed"
                  ></f7-list-input>
                  {{  month }}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Total Actual Hours</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.total_actual"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Efficiency</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.efficiency"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Training Hours</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.training_hours"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Comeback Hours</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.comeback_hours"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Adjusted Actual Hours</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.adj_hours"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Adjusted Efficiency</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.adjusted_actual"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Efficiency Bonus Points</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.eff_points"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Bonus Amount</div>
                <div class="item-input-wrap">
                  <f7-list-input
                    type="text"
                    :value="bonus.bonus"
                  ></f7-list-input>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </form>

      <div class="data-table-footer"></div>
    </div>
  </f7-page>
</template>

<script>
export default {
  data: function () {
    return {
      eff: "",
      bonus: "",
      comeback: "",
      isHidden: "",
      year: "2022",
      month: 10,
      orient: "land",
      isPort: null,
      wasPort: -1,
      header_title: "",
      secure: "",
      first_name: "",
      last_name: "",
      tech: "",
      yearArr: "",
      monthArr:"",
      init: true,
    };
  },

  mounted() {
    // alert(window.screen.orientation.type)
    // this.isPort = (window.innerHeight > window.innerWidth);
  },

  async created() {
    this.report_month();
    this.yearArray();
    this.monthArray();
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("secure")
      .then((value) => (this.secure = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });


   
    this.setTitle();
    this.loadData();
  },

  methods: {
    setTitle() {

      this.header_title =
        this.month_name(this.month) + " " + this.year + " Monthy Eff Totals";
      
    },

    loadData() {
      // const tch = this.first_name + this.last_name;
      var tch = this.first_name + this.last_name;
      const tchs = tch.split('.');
      if(tchs.length > 1){
      tch = tchs[1].trim();
      }
      
      this.tech = tch.toLowerCase();

      console.log(this.tech);
      const data = {
        secure: this.secure,
        tech: this.tech,
        year: this.year,
        month: this.month,
      };
      console.log(data);
      const url =
        "https://data.truckserviceco.com/eff_report/dashboard/fetch_data/mobile/index.php";
     axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            
          },
        })
        .then((response) => {
          console.log(response.data);
          this.eff = response.data.eff_details;
          this.bonus = response.data.bonus;
          this.comback = response.data.comeback;
          console.log(this.comeback);
        })
        // .then((response) => (this.eff = response.data.items))
        .catch(function (error) {
          console.log(error);
        });
    },

    set_eff(act, bill) {
      var efficency = bill / act;
      efficency = efficency * 100;
      return efficency.toFixed(2);
    },
    format_numbers(numb) {
      return numb.toFixed(2);
    },

    format_customer_name(name) {
      name = name.split("(");
      return name[0];
    },
    set_color(act, bill) {
      var cls = "";
      var efficency = bill / act;
      efficency = efficency * 100;
      switch (true) {
        case efficency > 99.9:
          cls = "success";
          break;
        case efficency > 89.9 && efficency < 100:
          cls = "info";
          break;
        case efficency > 79.9 && efficency < 90:
          cls = "warning";
          break;
        case efficency < 80:
          cls = "danger";
          break;

        default:
          cls = "";
      }
      return cls;
    },
    cust_font(cust) {
      var name = cust.split("(");
      cust = name[0];
      var len = cust.length;
      if (len > 15) {
        return "label-cell long_cust";
      } else {
        return "label-cell";
      }
    },
    month_name(mon) {
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return month[mon-1];
    },
    monthArray(){
      this.monthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

    },
    yearArray(){
      var date = new Date();
      var yy = this.$moment(date).format("YYYY");
      var years = [];
      
      while(yy >= 2018){
        years.push(yy);
        yy--;
    
      }
    this.yearArr = years;
    },
    report_month() {
      var dt = new Date();

      var yr = this.$moment(dt).format("YYYY");
      var mnth = this.$moment(dt).format("MM");
      var dy = this.$moment(dt).format("DD");

    
      if (mnth ==1) {
        yr = yr - 1;
        mnth = 12;
      }else{
       mnth = mnth - 1;
      }

      this.year = yr;
      this.month = mnth;
    
    },
    setMonth(val) {
    
      this.month = val;
      this.setTitle();
    
      this.loadData();
    },
    setYear(val) {
      this.year = val;
      this.setTitle();
      this.loadData();
    },
    selYear(val){
      if(val == this.year){
        return true;
      }else{
        return false;
      }
    },
    selMon(val){

    if(val == this.month){
        return true;
      }else{
        return false;
      }
    },
    effDetails() {
      const self = this;
      const router = self.$f7router;
  
      router.navigate(
        "/monthlyEff/year/" + this.year + "/month/" + this.month+ "/"
      );
    },
  },
};
</script>

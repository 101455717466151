<template>
  <f7-page name="employeeportal">
    <f7-navbar title="Employee Portal" back-link="Back"></f7-navbar>
    <!-- Toolbar-->

    <!-- Page content-->

    <f7-list>
      <f7-list-item link="/vacations/" title="Vacations"></f7-list-item>
      <!-- <f7-list-item link="/calendar/" title="Calendar"></f7-list-item> -->
      <!-- <f7-list-item link="/addvacations/" title="Add Vacation"></f7-list-item> -->
      <f7-list-item
       v-if="checkAccess(['tech','admin'], access)" 
        link="/monthlyEffTotals/"
        title="Monthly Eff Totals"
      ></f7-list-item>
      <!-- <f7-list-item link="/updatepwd/id/6" title="Password"></f7-list-item>
           <f7-list-item link="/profile/" title="Profile"></f7-list-item> -->
    </f7-list>
  </f7-page>
</template>
<script>
import { accessMixin } from "../../mixins/accessMixin.js";
export default {
  mixins: [accessMixin],
  data: function () {
    return {
      access: [],
    };
  },

  mounted() {
    var access = this.$vlf.createInstance({
      storeName: "access",
    });

    access
      .getItem("access")
      .then((value) => (this.access = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
  },
};
</script>
<template>
  <div>
    <f7-list>
      <!-- <f7-list-input
        class="searchbar-demo tapHold"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keyup.native="searchCustomers($event), showList()"
        @keydown.native="isTab($event)"
        @dblclick.native="clearValue()"
        @tapHold.native="clearValue()"
      ></f7-list-input> -->
      
    </f7-list>

      <f7-list>
        <f7-list-item
          v-for="partscounter in partscounters"
          :key="partscounter.id"
          :value="partscounter.customer"
          @click="selectCustomer(partscounter.id, customer.customer)"
        >
        {{ partscounter.part_no }}
        </f7-list-item>
      </f7-list>
 
  </div>
</template>
  <script>
export default {
  props: {
    customer_id: [Number, String],
    customer: Object,
    userid: [Number, String],
  },
  data() {
    return {
      keyword: "",
      showall: true,
      partscounters: {},
      custId:"",
    };
  },

  async created() {
    this.custId = this.$f7route.params.id;

    // if (this.customer_id != 0) {
      const data = {
        customer_id: this.customer_id,
        userid: this.userid,
        showall:true,

      };
      const url = apiUrl + "/api/partscounters/list";
console.log(data)
      await axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
            console.log(response)
          this.partscounters = response.data;
       
        })
        .catch(function (error) {
          console.log(error);
        });
    // } else {
    //   console.log("false");
    // }
  },

  methods: {},
};
</script>
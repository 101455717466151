<template>
  <f7-page name="part">
   <f7-navbar title="Part" back-link="Back"></f7-navbar> 
    <!-- Toolbar-->

    <div class="card">
      <div class="card-header">
        <div class="head">
          <f7-badge
            class="badge lft"
            :class="removeSpace(part._urgency.value)"
            >{{ part._urgency.value }}</f7-badge
          >
          <f7-badge
            class="badge ctr"
            :class="removeSpace(part._action.value)"
            >{{ part._action.value }}</f7-badge
          >
          <f7-badge
            class="badge rght"
            :class="removeSpace(part._location.value)"
            >{{ part._location.value }}</f7-badge
          >
        </div>
      </div>
      <div class="card-content">
        <f7-list>
          <f7-list-item title="Vendor:">{{ part.vendor.name }} </f7-list-item>
          <f7-list-item title="Order #:">
            {{ part.orderno }}
          </f7-list-item>
          <f7-list-item title="Details:">
            {{ part.details }}
          </f7-list-item>
          <f7-list-item
            v-if="
              part.specialInstructions !== null &&
              part.specialInstructions !== ''
            "
            title="Special Instructions:"
          >
            {{ part.specialInstructions }}
          </f7-list-item>
          <f7-list-item title="Requested By:">
            {{ part.user.full_name }}
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card-footer">
        <div v-if="part._status !== null" class="tstamp">
          {{ part._status.value }}
          <span v-if="part._status_by !== null"
            >By {{ part._status_by.full_name }}
          </span>
          <span v-if="part._modified !== null"
            >@ {{ format_timestamp(part.modified) }}
          </span>
        </div>
      </div>
      <div class="">
        <f7-list accordion-list inset>
          <f7-list-item
            id="note"
            accordion-item
            title="Notes"
            :badge="noteCount"
            badge-color="red"
          >
            <f7-accordion-content id="note">
              <Notes
                :model="model"
                :notes="notes"
                :userid="uid"
                :entid="part.id"
                :ndb="ndb"
                @notesCount="setNoteCount"
                @updateNotes="setNotes"
              />
            </f7-accordion-content>
          </f7-list-item>
          <div>
            <f7-button
              v-on:click="opencam"
              class="button"
              color="blue"
              fill
              raised
            >
              Add Pic and Mark {{ buttons[0][0] }}
            </f7-button>
          </div>
          <f7-list-item
            accordion-item
            title="Pictures"
            :badge="picCount"
            badge-color="red"
          >
            <f7-accordion-content>
              <f7-swiper
                v-if="pics.length > 0"
                pagination
                navigation
                scrollbar
                :loop="false"
                :speed="500"
                :slidesPerView="1"
                :spaceBetween="20"
              >
                <f7-swiper-slide
                  v-for="(pic, index) in pics"
                  :key="index"
                  :title="pic.status"
                  @taphold.native="deletePic(pic)"
                >
            

                  <figure>
                    <figcaption class="pic_details">{{pic.status}} By {{ pic.picBy }} @ {{ pic.date }} {{ pic.time }} </figcaption>
                    <img
                      class="tapHold"
                      :src="domain + fileUrl + pic.src"
                      @dblclick="deletePic(pic.src)"
                    />
                  </figure>
                </f7-swiper-slide>
                <f7-swiper-slide
                  ><div v-on:click="opencam">
                    <div>Add image...</div>
                    <i class="icon f7-icons color-blue slider_add">camera</i>
                  </div></f7-swiper-slide
                >
              </f7-swiper>

              <f7-block v-else-if="pics.length == 0" class="upload_icon">
                <div v-on:click="opencam">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div>
              </f7-block>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
        <div style="height: 40px"></div>
        <div></div>
        <f7-popup id="camera" class="cam-popup">
          <!-- <f7-popup id="camera" class="cam-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
          <f7-page>
            <f7-navbar title="Upload Pictures">
              <f7-nav-right>
                <f7-link popup-close>Close</f7-link>
              </f7-nav-right>
            </f7-navbar>

            <Camera
              :model="model"
              :item="part"
              :uid="uid"
              :params="params"
              back = "true"
              @picList="setPics"
              @docList="setDocs"
            />
          </f7-page>
        </f7-popup>
      </div>
      <div>
        <f7-button
          v-for="button in buttons"
          :key="button[4]"
          :color="button[5]"
          @click.prevent="update_status(button[4])"
          class="button"
          fill
          raised
          >{{ button[0] }}</f7-button
        >
        <f7-button fill raised color="red" @click="$f7router.back()"
          >Cancel</f7-button
        >
      </div>
    </div>
  </f7-page>
</template>

<style>
.button {
  margin-bottom: 15px;
}
.head {
  width: 100%;
}
.pic_details{
  font-size:50%;
}
</style>
  <script>
import Notes from "../../components/Notes.vue";
import Camera from "../../components/CameraComp.vue";
export default {
  components: {
    Notes,
    Camera,
  },
  data: function () {
    return {
      ndb: "requestnotes",
      nav:"partdaily",
      params: [],
      uid: 0,
      id: "",
      token: "",
      model: "dailyparts",
      entid: 0,
      notes: [],
      noteCount: "",
      pics: "",
      picCount: "",
      part: {
        vendor: {
          name: "",
        },
        _urgency: {
          value: "",
        },
        _location: {
          value: "",
        },
        _action: {
          value: "",
        },
        user: {},
        _status: {},
        _status_by: {},
      },
      record: "",
      status: "",
      buttons: [],
      fileUrl: "/webroot/files/Dailyparts/statusPic/",
      domain:"",
    };
  },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });

    await userStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    await userStore
      .getItem("id")
      .then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    this.id = this.$f7route.params.id;
    this.domain = apiUrl;
    this.loadData();
  },

  methods: {
    alert(id) {
      alert(id);
    },
  
    loadData() {
      const data = { id: this.id };
      const url = apiUrl + "/api/dailyparts/view";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.part = response.data.dailypart;
          this.buttons = response.data.buttons;
          this.params = "next_status=" + response.data.buttons[0][4];
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
          this.notes = response.data.dailypart.requestnotes;
          this.noteCount = this.setBadge(
            response.data.dailypart.requestnotes.length
          );
        })
        .catch(function (error) {
          console.log(error);
        });

      //  }).then(response => console.log(response.data)).catch(function(error){console.log(error)});
      //.then(response => this.posts = response.data.data)
      this.$f7ready((f7) => {
        //  f7.dialog.alert('Component mounted');
      });
    },
    update_status(status) {
      const router = this.$f7router;
      const data = { id: this.id, status: status, user_id: this.uid };
      const url = apiUrl + "/api/dailyparts/update";
     
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response.data);
          router.back({
            force: true,
            ignoreCache: true,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    lowerCase(value) {
      value = value.replace(/\s/g, "");
      return value.toLowerCase();
    },
    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    format_timestamp(timestamp) {
      var date = this.$moment(timestamp).format("YYYY-MM-DD");
      var time = this.$moment(timestamp).format("hh:mm a");
      return date + " " + time;
    },
    update(id) {
      alert(id);
    },
    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    setNoteCount(noteCount) {
      console.log(noteCount);
      this.noteCount = this.setBadge(noteCount);
    },
    setNotes(notes) {
      console.log(notes);
      this.notes = notes;
    },
    opencam() {
      const app = this.$f7;
      app.popup.open("#camera");
    },
    navigate(){
      console.log('test')
    },
    setPics(pics) {
      this.pics = pics;
      this.picCount = this.setBadge(pics.length);
    },
    setDocs(docs) {
      this.docs = docs;
      this.docCount = this.setBadge(docs.length);
    },
    formatTitle(val) {
      val = val.toString().split("_");
      return val;
    },
    deletePic(pic) {
      var del = confirm("Delete this image?");

      if (del == true) {
        this.sendDeleteRequest(pic);
      }
    },
    sendDeleteRequest(filename) {
      const data = {
        filename: filename,
      };
      console.log(data);
      const url = apiUrl + "/api/dailyparts/deletepic";

      axios
        .post(url, data, {
          origin:true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response)
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
          this.docs = response.data.docs;
          this.docCount = this.setBadge(response.data.docs.length);
          // console.log(response)
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      setPics(pics) {
      this.pics = pics;
      this.picCount = this.setBadge(pics.length);
    },
    setDocs(docs) {
      this.docs = docs;
      this.docCount = this.setBadge(docs.length);
    },
  },
};
</script>
<template>
  <f7-page name="list">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>Credit Request List</f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keypress.native="searchTask($event)"
        @change="refreshCreditRequestList($event)"
      ></f7-searchbar>
    </f7-navbar>
    <div class="card">
      <f7-list>

        <f7-list-item
          v-for="creditrequest in creditrequests"
          :key="creditrequest.id"
          :link="'/request/' + creditrequest.id"
          :title="creditrequest.customer.name"
          no-chevron
        >
        <f7-badge
        class="badge sm-badge"
        :class="removeSpace(creditrequest._status.value)"
        >
        {{ creditrequest._status.value }}
        </f7-badge>
        <f7-badge
        class="badge sm-badge"
        :class="removeSpace(creditrequest._branch.value)"
        >
        {{ firstLetter(creditrequest._branch.value) }}
        </f7-badge>
        </f7-list-item>




      </f7-list>
    </div>
    <f7-toolbar bottom class="bottom-toolbar">
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>
   
    
      
      <f7-link href="/addcreditrequest/"
        ><i class="icon f7-icons color-blue">plus</i></f7-link
      >
    </f7-toolbar>
  </f7-page>
</template>
  <script>
export default {
  components: {},
  data() {
    return {
      keyword: "",
      creditrequests: "",
    };
  },
  mounted() {},

  async created() {
    var userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    var credStore = this.$vlf.createInstance({
      storeName: "cred",
    });
    await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    const data = {
      keyword:this.keyword,
    };
    const token = this.token;
    console.log(data);
    console.log(this.token);
    const url = apiUrl + "/api/creditrequests/index";
    console.log(url);
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.creditrequests = response.data;
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    searchTask(event) {
      this.search = true;
      this.refreshCreditRequestList();
    },

    refreshCreditRequestList() {
      const url = apiUrl + "/api/creditrequests/index";
    var data = {
      keyword:this.keyword,
    }

   axios.post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.creditrequests = response.data;
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

   

    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    firstLetter(val){
      return val[0];
    }
  }
};
</script>
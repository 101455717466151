export const pictureMixin = {
    // data(){
    //     return {
    //     picCount:'',
    //     }
    // },

    methods:{
        setPics(pics) {
            this.pics = pics;
            this.picCount = this.setBadge(pics.length);
          },
        deletePic(pic) {
            const del = confirm("Delete this image?");
      
            if (del == true) {
              this.sendDeleteRequest(pic);
            }
          },
          formatTitle(val) {
            val = val.split("_");
            return val[2];
          },

        sendDeleteRequest(filename) {
            const data = {
              filename: filename,
            };
            console.log(data);
            const url = apiUrl + "/api/tasks/deletedoc";
      
            axios
              .post(url, data, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + TOKEN,
                },
              })
              .then((response) => {
                this.pics = response.data.pics;
                this.picCount = this.setBadge(response.data.pics.length);
                this.docs = response.data.docs;
                this.docCount = this.setBadge(response.data.docs.length);
                // console.log(response)
              })
              .catch(function (error) {
                console.log(error);
              });
    }

}
}
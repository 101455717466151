<template>
  <f7-page name="transfer">
  <f7-navbar title="Order Transfers" back-link="Back"></f7-navbar>
 

  <f7-row v-for="item in transfer" :key="item.id" >
    <f7-row>
<f7-col>{{item.part_no}}</f7-col>
<f7-col>   {{item.received_qty}}</f7-col>
<f7-col>
    
    </f7-col>
    <f7-row>
        {{item.purchaseorder.description}}
    </f7-row>
  </f7-row>
</f7-row>
   

<!-- <f7-list>

          <f7-list-item
            v-for="item in transfer"
            :key="item.id"
          
          >
          Part No: {{ item.part_no}}     Transfered: {{item.received_qty}}<br>
           {{item.purchaseorder.description}}
          </f7-list-item>
     
        </f7-list> -->




    <f7-list>
      <f7-list-item link="/" title="Home"></f7-list-item>
    </f7-list>


  </f7-page>
</template>
<style>
.demo-card-header-pic .card-header {
  height: 40vw;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.demo-card-header-pic .card-content-padding .date {
  color: #8e8e93;
}
.demo-facebook-card .card-header {
  display: block;
  padding: 10px;
}
.demo-facebook-card .demo-facebook-avatar {
  float: left;
}
.demo-facebook-card .demo-facebook-name {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 500;
}
.demo-facebook-card .demo-facebook-date {
  margin-left: 44px;
  font-size: 13px;
  color: #8e8e93;
}
.demo-facebook-card .card-footer {
  background: #fafafa;
}
.dark .demo-facebook-card .card-footer {
  background-color: transparent;
}
.demo-facebook-card .card-footer a {
  color: #81848b;
  font-weight: 500;
}
.demo-facebook-card .card-content img {
  display: block;
}
.demo-facebook-card .card-content-padding {
  padding: 15px 10px;
}
.demo-facebook-card .card-content-padding .likes {
  color: #8e8e93;
}
</style>
<script>

export default {
   data: function () {
    return {
      token : '',
      uid:'',
      transfer:[],
    
    };
   },

  async created()  {

       const credStore =  this.$vlf.createInstance({
           storeName: 'cred'
       });

await credStore.getItem('token').then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

await credStore.getItem('uid').then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
this.loadData();
},

methods:{

  loadData(){
const dt = {search:'test'};
const url = apiUrl + "/api/receives/shoptransfers";
console.log(this.token);
axios.post(url, dt,  {
   headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization" : "Bearer " + TOKEN
        }
 })
//  .then(response => console.log(response.data))
 .then(response => {
   this.transfer = response.data.transfer;
    console.log(response.data)
 })
 .catch(function(error){console.log(error)});

//  }).then(response => console.log(response.data)).catch(function(error){console.log(error)});
//.then(response => this.posts = response.data.data)
     this.$f7ready((f7) => {



      //  f7.dialog.alert('Component mounted');


     });
   },


}//end methods

}

</script>
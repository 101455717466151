<template>
  <f7-page name="tool">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/toollist/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title> Add Tool </f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
    
    </f7-navbar>
 
    <f7-list strong-ios outline-ios dividers-ios>
      <f7-list-item>
        <f7-list-input
          label="Tool Name"
          type="text"
          placeholder="Tool Name..."
          clear-button
          :value="tool.name"
          @input="tool.name = $event.target.value"
        />
      </f7-list-item>

      <f7-list-item
        title="Category"
        smart-select
        ref="category"
        :smart-select-params="{
          openIn: 'popup',
          searchbar: true,
          searchbarPlaceholder: 'Search Catgeories',
        }"
      >
        <select
          name="category"
          ref="category"
          @change="closeCat()"
          v-model="tool.category_id"
        >
          <option value="">Select...</option>
          <option
            v-for="category in categories"
            :key="category.id"
            :value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </f7-list-item>
      <!-- Category list -->
      <f7-list-item
        title="Popularity"
        smart-select
        ref="popularity"
        :smart-select-params="{
          openIn: 'popup',
          searchbar: true,
          searchbarPlaceholder: 'Select Popularity',
        }"
      >
        <select
          name="popularity"
          ref="popularity"
          @change="closePop()"
          v-model="tool.popularity"
        >
          <option
            v-for="popularity in popularities"
            :key="popularity.id"
            :id="popularity.id"
            :value="popularity.id"
          >
            {{ popularity.id }}
          </option>
        </select>
      </f7-list-item>
      <!-- Popularity list-->
      <f7-list-item>
        <f7-list-input
          label="Part Number"
          type="text"
          placeholder="Part Number..."
          clear-button
          :value="tool.partNumber"
          @input="tool.partNumber = $event.target.value"
        />
      </f7-list-item>
      <f7-list-item>
        <f7-list-input
          label="Description"
          type="textarea"
          placeholder="Description"
          name="description"
          :value="tool.description"
          @input="tool.description = $event.target.value"
        ></f7-list-input>
      </f7-list-item>
    </f7-list>
    <ShopList
      label="Home Shop"
      buttons="false"
      :defaultBranch="tool.location"
      @branchUpdated="updateBranch"
    />
    <f7-list>
      <f7-list-input
        label="Storage Location (room, toolbox, wall, etc.)"
        type="text"
        placeholder="Storage Location..."
        clear-button
        :value="tool.storage_location"
        @input="tool.storage_location = $event.target.value"
      />
    </f7-list>
    <ShopList
      label="Current Shop"
      buttons="false"
      :defaultBranch="tool.current_location"
      @branchUpdated="updateCurrent"
    />
    <!-- shop list (current shop location)-->
    <f7-list>
      <VendorSearch
        :vendor_id="vendor_id"
        @vendorDetails="updateVendorDetails"
      />
      <!-- Vendor search -->

      <f7-list-input
        label="Purchase Date"
        type="date"
        :value="tool.date_purchased"
        placeholder="Please choose..."
        @input="tool.date_purchased = $event.target.value"
      />

      <f7-list-input
        label="Serial Number"
        type="text"
        placeholder="Serial Number..."
        clear-button
        :value="tool.serialno"
        @input="tool.serialno = $event.target.value"
      />
      <f7-list-input
        label="Purchase Price"
        type="number"
        placeholder="Purchase Price..."
        clear-button
        :value="tool.purchase_price"
        @input="tool.purchase_price = $event.target.value"
      />
      <f7-list-item
        checkbox
        title="Out of Service"
        name="outOfService"
        @click="oos(tool.outOfService)"
      ></f7-list-item>
    </f7-list>
    <f7-block strong>
      <f7-row>
        <f7-col>
          <f7-button fill color="red" @click="$f7router.back()"
            >Cancel</f7-button
          >
        </f7-col>
        <f7-col>
          <f7-button
            fill
            color="green"
            @click.prevent="submitRequest"
            :disabled="disabled"
            >Submit Request</f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>
import ShopList from "../../components/ShopList.vue";
import VendorSearch from "../../components/VendorSearch.vue";
export default {
  components: {
    ShopList,
    VendorSearch,
  },
  data() {
    return {
      branch: "",
      categories: "",
      vendor_id: "",
      current: "",
      disabled: true,
      popularities: {
        1: { id: "A", value: "A" },
        2: { id: "B", value: "B" },
        3: { id: "C", value: "C" },
      },
      tool: {
        userId:"",
        name: "",
        popularity: "A",
        category_id: "",
        partNumber: "",
        description: "",
        location: "",
        storage_location: "",
        current_location: "",
        vendor_id: "",
        date_purchased: "",
        serialno: "",
        purchase_price: "",
        outOfService: 0,
      },
    };
  },
  async created() {
    const url = apiUrl + "/api/tools/categories";

    const router = this.$f7router;
    const data = {};
    axios
      .post(url, data, {
        origin: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        this.categories = response.data;
        console.log(response.data);
        console.log(this.categories);
      })
      .catch(function (error) {
        console.log(error);
      });
      var user =  this.$vlf.createInstance({
        storeName: 'user'
    });
    user.getItem("id")
      .then((value) => (this.tool.userId = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
  },
  methods: {
    submitRequest() {
      const url = apiUrl + "/api/tools/add";

      const router = this.$f7router;
      const data = this.tool;
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.categories = response.data;
          console.log(response.data);
          router.back({
            force: true,
            ignoreCache: true
          });
      
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    oos(val) {
      switch (val) {
        case 1:
          this.tool.outOfService = 0;
          break;
        case 0:
        case "":
          this.tool.outOfService = 1;
          break;
      }
    },
    submitRequeste() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      console.log(this.tool);
    },
    updateBranch(val) {
      this.tool.location = val;
    },

    updateVendorDetails(val) {
      this.tool.vendor_id = val.vendor_id;
    },

    updateCurrent(val) {
      this.tool.current_location = val;
      this.checkRequired();
    },
    closeCat() {
      this.$refs.category.f7SmartSelect.close();
      this.checkRequired();
      console.log(this.tool);
    },
    closePop() {
      this.$refs.popularity.f7SmartSelect.close();
      this.checkRequired();
      console.log(this.tool.popularity);
    },
    checkRequired() {
      this.disabled = false;
      if (this.tool.name == "") {
        this.disabled = true;
      }
      if (this.tool.category_id == "") {
        this.disabled = true;
      }
      if (this.tool.popularity == "") {
        this.disabled = true;
      }
      if (this.tool.partNumber == "") {
        this.disabled = true;
      }
      if (this.tool.location == "") {
        this.disabled = true;
      }
      if (this.tool.current_location == "") {
        this.disabled = true;
      }
    },
  },
};
</script>

<template>
  <f7-page name="inspection">
    <f7-list strong-ios outline-ios dividers-ios>
      <f7-list-item
        title="Unit"
        smart-select
        ref="unit"
        :smart-select-params="{
          openIn: 'popup',
          searchbar: true,
          searchbarPlaceholder: 'Search Units',
        }"
      >
        <select
          id="unit"
          name="unit"
          v-model="selectedUnit"
          @change="closeSelect"
        >
          <option
            v-for="unit in units"
            class="unit_select"
            :key="unit.id"
            :value="unit.id"
            :selected="unit_id == unit.id"
          >
            {{ unit.nickname }}
          </option>
        </select>
      </f7-list-item>
    </f7-list>

    <f7-navbar title="Inspection" back-link="Back"></f7-navbar>
    <f7-list>
      <f7-list-item v-if="inspection.completed != null" class="cmplt" :title="'Completed: ' + inspectDetail(inspection.complete,inspection._completed_by.full_name)" >
      </f7-list-item>
      <f7-list-item v-if="inspection.out_of_service == 1" class="oos" :title="'Out Of Service'" >

    </f7-list-item>
      <f7-list-item :title="'Inspection Date: ' + inspection_date">
      </f7-list-item>
      <f7-list-input
        label="Hours:"
        type="text"
        :value="inspection.meter"
        placeholder="Enter meter reading..."
        @input="inspection.meter = $event.target.value"
      >
      </f7-list-input>
      <f7-list-item
        :title="'Inspector: ' + user.first_name + ' ' + user.last_name"
      >
      </f7-list-item>
      <f7-list-item>
      <p class="instr" >*Mark only items you find an issue with. Record and notes and take pictures to document issues.</p>
    </f7-list-item>
    </f7-list>
    <f7-list media-list outline-ios dividers-ios>
    
      <div v-for="(item, index) in items" :key="item.id">
        <div>
          <f7-block-title
            v-if="
              index == 0 ||
              (index > 0 && item.items_group !== items[index - 1].items_group)
            "
            >{{ item.items_group }}</f7-block-title
          >
        </div>
        <f7-list-item
          :title="item.inspection_item"
          :text="item.details"
          :id="item.id"
          :value="item.inspection_item"
          @change="
            ($event) => {
              needsMaintenance(item.inspection_item, $event);
            }
          "
          no-chevron
          checkbox
          :checked="maintenanceItems.indexOf(item.inspection_item) >= 0"
        ></f7-list-item>
      </div>
     
      <f7-list-item>
        <f7-block strong outline-ios>
          <f7-row>
            <f7-col>
              <f7-button fill color="green" @click="save">Save</f7-button>
            </f7-col>
            <f7-col>
              <f7-button fill color="red" href="/">Cancel</f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-list-item>
    </f7-list>
    <div class="upper-area">
      <f7-list accordion-list inset>
        <f7-list-item
          id="note"
          accordion-item
          title="Notes"
          :badge="noteCount"
          badge-color="red"
        >
          <f7-accordion-content id="note">
            <Notes
              :model="model"
              :notes="notes"
              :userid="user.id"
              :entid="inspection.id"
              @notesCount="setNoteCount"
              @updateNotes="setNotes"
            />
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item
          accordion-item
          title="Pictures"
          :badge="picCount"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
              v-if="pics.length > 0"
              pagination
              navigation
              scrollbar
              :loop="false"
              :speed="500"
              :slidesPerView="1"
              :spaceBetween="20"
            >
              <f7-swiper-slide
                v-for="(pic, index) in pics"
                :key="index"
                :title="pic"
                @taphold.native="deletePic(pic)"
              >
                <figure>
                  <figcaption>{{ formatTitle(pic) }}</figcaption>
                  <img
                    class="tapHold"
                    :src="domain + fileUrl + pic"
                    @dblclick="deletePic(pic)"
                  />
                </figure>
              </f7-swiper-slide>
              <f7-swiper-slide>
                <div v-on:click="opencam">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div>
              </f7-swiper-slide>
            </f7-swiper>

            <f7-block v-else-if="pics.length == 0" class="upload_icon">
              <div v-on:click="opencam">
                <div>Add image...</div>
                <i class="icon f7-icons color-blue slider_add">camera</i>
              </div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <div style="height: 40px"></div>
      <f7-popup id="camera" class="cam-popup">
        <!-- <f7-popup id="camera" class="cam-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
        <f7-page>
          <f7-navbar title="Upload Pictures">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <Camera
            :model="model"
            :item="inspection"
            :uid="user.id"
            @picList="setPics"
            @docList="setDocs"
          />
        </f7-page>
      </f7-popup>
      <f7-popup id="fileloader" class="file-popup">
        <!-- <f7-popup id="fileloader" class="file-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
        <f7-page>
          <f7-navbar title="Upload Files">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <FileLoader
            :item="inspection"
            :model="model"
            :uid="user.id"
            @picList="setPics"
            @docList="setDocs"
          />
        </f7-page>
      </f7-popup>
    </div>
  </f7-page>
</template>

<script>
import Notes from "../../components/Notes.vue";
import Camera from "../../components/CameraComp.vue";
import FileLoader from "../../components/FileLoader.vue";

export default {
  components: {
    Notes,
    Camera,
    FileLoader,
  },

  data() {
    return {
      selectedUnit: 0,
      unit_id: 0,
      test: "",
      maintenanceItems: ["Seat Belt"],
      inspections: "",
      items: "",
      inspector: "",
      units: "",
      instructions: "",
      inspection: {
        id: 0,
        meter:0,
      },
      inspection_date: "2024-01-18",
      meter: "",
      domain: apiUrl,
      fileUrl: "/webroot/files/Inspections/pics/",
      model: "Inspections",
      tid: "",
      pics: "",
      picCount: "",
      notes: [],
      noteCount: "",
      type: "forklift",
      oos: false,
      status: "",
      complete:false,
      completed_by:"",
      user: {
        first_name: "",
        last_name: "",
        id:0,
        shop: "",
        token: "",
      },
    };
  },
  mounted() {
    this.inspection.id = this.$f7route.params.id;
    this.domain = apiUrl;
  },
  async created() {
    var userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    userStore
      .getItem("first_name")
      .then((value) => (this.user.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("last_name")
      .then((value) => (this.user.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("token")
      .then((value) => (this.user.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    console.log(this.inspection.id);
    const data = {
      keyword: this.keyword,
      limit: this.limit,
      shop: this.shop,
      id: this.$f7route.params.id,
      type: this.type,
      userid: this.user.id,
    };
    const token = this.user.token;
    console.log(data);
    console.log(this.user.token);
    const url = apiUrl + "/api/inspections/inspection";

    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        console.log(response);
        this.inspection = response.data.inspection;
        this.items = response.data.items;
        this.inspector = response.data.inspector;
        this.units = response.data.units;
        this.instructions = response.data.instructions;
        this.maintenanceItems = response.data.inspection.needs_maintenance;
        this.selectedUnit = response.data.inspection.equipment_id;
        this.notes = response.data.inspection.notes;
        this.noteCount = this.setBadge(response.data.inspection.notes.length);
        this.pics = response.data.pics;
        this.picCount = this.setBadge(response.data.pics.length);
        this.inspection_date = this.$moment(
          response.data.inspection.created,
          "YYYY-MM-DD"
        ).format("MM-DD-YYYY");
      })
      .finally(() => {
        this.$refs.unit.f7SmartSelect.setValue(this.selectedUnit);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    needsMaintenance(id, $event) {
      //in here you can check what ever condition  before append to array

      if (this.maintenanceItems.includes(id)) {
        var loc = this.maintenanceItems.indexOf(id);
        this.maintenanceItems.splice(loc, 1);
      } else {
        // console.log($event.target);
        // console.log($event.target.attributes);
        // $event.target.checked = "checked";
        this.maintenanceItems.push(id);
        // console.log($event.target.checked);
      }
    },
    checked(id) {
      if (this.maintenanceItems.includes(id)) {
        return "checked";
      } else {
        return false;
      }
    },
    testclick() {
      alert("here");
    },

    save() {
      if (this.maintenanceItems.length > 0) {
        if (confirm("CLICK \"OK\" if some of the items are\"OUT OF SERVICE\" issues!")) {
          this.oos = true;
          this.status = 'Out Of Service';
        }else{
          this.oos = false;
        }
      }
      if(confirm("CLICK \"OK\" if this inspection is \"COMPLETE\"")){
        this.complete = true;
        this.completed_by = this.user.id;
        if(this.oos == false){
          this.status = 'Passed';
        }else{
          this.status = 'Out Of Service';
        }
      }else{
        this.complete = false;
        this.completed_by = "";
      }
      const self = this;
      const router = self.$f7router;

      const data = {
        id: this.inspection.id,
        equipment_id: this.selectedUnit,
        needs_maintenance: this.maintenanceItems,
        out_of_service: this.oos,
        meter: this.inspection.meter,
        user_id: this.user.id,
        status: this.status,
        complete:this.complete,
        completed_by: this.completed_by,
      };
      console.log(data);
      const url = apiUrl + "/api/inspections/save";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response);
          router.back({
            force: true,
            ignoreCache: true
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closeSelect() {
      this.$refs.unit.f7SmartSelect.close();
    },
    searchTask(event) {
      // if (event.keyCode == 13) {
      // event.preventDefault();
      this.search = true;
      this.loadTools();
      // }
    },
    testToggle() {
      this.showall = !this.showall;
      this.loadTools();
    },
    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    save_inspect() {
      confirm("Inspection is complete and I'm ready to submit?");
    },
    opencam() {
      const app = this.$f7;
      app.popup.open("#camera");
    },
    upload_file() {
      const app = this.$f7;
      app.popup.open("#fileloader");
    },

    toggleNote() {
      alert("add note");
    },

    formatTime(time) {
      var time = new Date(time);

      return this.$moment(time).format("YYYY-MM-DD h:mm A");
    },
    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    setPics(pics) {
      this.pics = pics;
      this.picCount = this.setBadge(pics.length);
    },
    setDocs(docs) {
      this.docs = docs;
      this.docCount = this.setBadge(docs.length);
    },
    setNoteCount(noteCount) {
      console.log(noteCount);
      this.noteCount = this.setBadge(noteCount);
    },
    setNotes(notes) {
      console.log(notes);
      this.notes = notes;
    },
    formatTitle(val) {
      val = val.split("_");
      return val[2];
    },
    deletePic(pic) {
      var del = confirm("Delete this image?");

      if (del == true) {
        this.sendDeleteRequest(pic);
      }
    },
    testTap() {
      alert("yes");
    },
    inspectDetail(completed,by){
     return this.setDetails(completed) + ' by ' + by;

    },
    setDetails(time, pos) {
      var date = this.$moment(time).format("MM-DD-YYYY");
      var tme = this.$moment(time).format("h:mm a");
      return date + " " + tme;
    },
  },
};
</script>
<style>
.tool_img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.instr {
  color:red;
  font-style:italic;
  text-align: center;
}
.cmplt{
  background-color:green;
color:white;
}
.oos{
  background-color:red;
color:white;
}
</style>
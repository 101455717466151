<template>
  <f7-page name="inspectionlist">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title> Inspection List </f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keypress.native="searchInspections($event)"
        @click.native = "keyword = '', searchInspections($event)"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list>
      <f7-list-item link="/inspection/0" title="New Inspection"></f7-list-item
    ></f7-list>
    <f7-list>
    <f7-list-item 
    v-for="inspection in inspections" 
    :key="inspection.id"
    :link="'/inspection/' + inspection.id"
    :title="inspection.equipment.nickname"
    ><span :class="status(inspection.created,inspection.completed)">
{{ formatDetails(inspection.created,inspection.completed) }}
</span></f7-list-item>
</f7-list>

</f7-page>

  </f7-page>
</template>

        <script>
export default {
  components: {},
  data() {
    return {
      keyword: "",
      inspections: "",
      items: "",
      inspector: "",
      units: "",
      instructions: "",
    };
  },
  mounted() {},

  async created() {
    var userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    var credStore = this.$vlf.createInstance({
      storeName: "cred",
    });
    await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    const data = {
      keyword: this.keyword,
      limit: this.limit,
      shop: this.shop,
      showall: this.showall,
    };
    const token = this.token;
    console.log(data);
    console.log(this.token);
    const url = apiUrl + "/api/inspections/list";

    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.inspections = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    loadInspections() {
      // alert(this.keyword)
      const data = {
        keyword: this.keyword,
        limit: this.limit,
        shop: this.shop,
        showall: this.showall,
      };
      console.log(data);
      const url = apiUrl + "/api/inspections/list";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.inspections = response.data;
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    searchInspections(event) {
      // if (event.keyCode == 13) {
      // event.preventDefault();
      this.search = true;
      this.loadInspections();
      // }
    },
    testToggle() {
      this.showall = !this.showall;
      this.loadInspections();
    },
    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    formatDetails(start, completed) {
      if (completed == "" || completed == null) {
        return this.setDetails(start, "start");
      } else {
        return this.setDetails(completed, "completed");
      }
    },
    setDetails(time, pos) {
      var date = this.$moment(time).format("MM-DD-YYYY");
      var tme = this.$moment(time).format("h:mm a");
      return date + " " + tme;
    },
    status(start, completed) {
      if (completed == "" || completed == null) {
        return "start";
      } else {
        return "complete";
      }
    },
  },
};
</script>
  <style>
.tool_img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.start {
  color: red;
}
.complete {
  color: green;
}
</style>
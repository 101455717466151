<template>
  <f7-page name="tool">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/listcreditrequest/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title> Add Credit Request </f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <f7-list strong-ios outline-ios dividers-ios>
      <CustomersList
        :customer_id="creditrequest.customer_id"
        @customerDetails="customerDetailsUpdate"
      />
    </f7-list>
    <ShopList
      label="Requesting Shop"
      buttons="false"
      :defaultBranch="creditrequest.branch"
      @branchUpdated="updateBranch"
    />
    <!-- SHOP -->
    <f7-list>
      <f7-list-item>
        <f7-list-input
          label="Contact Name"
          type="text"
          placeholder="Contact Name..."
          clear-button
          :value="creditrequest.contact_name"
          @input="creditrequest.contact_name = $event.target.value"
        />
      </f7-list-item>
      <f7-list-item>
        <f7-list-input
          label="Contact Phone"
          type="text"
          placeholder="Contact Phone..."
          clear-button
          :value="creditrequest.contact_phone"
          @input="creditrequest.contact_phone = $event.target.value"
        />
      </f7-list-item>
      <f7-list-item>
        <f7-list-input
          label="Contact Email"
          type="text"
          placeholder="Contact Email..."
          clear-button
          :value="creditrequest.contact_email"
          @input="creditrequest.contact_email = $event.target.value"
        />
      </f7-list-item>
      <f7-list-item>
        <f7-list-input
          label="Accounting Email"
          type="text"
          placeholder="Accounting Email..."
          clear-button
          :value="creditrequest.accounting_email"
          @input="creditrequest.accounting_email = $event.target.value"
        />
      </f7-list-item>
    </f7-list>
    <DynamicList
      label="Customer Type"
      type="customer_type"
      :except="except.none"
      :defaultValue="creditrequest.customer_type"
      @valueUpdated="updateFieldValue"
    />
    <DynamicList
      label="Request Priority"
      type="creditrequest_priority"
      :except="except.none"
      :defaultValue="creditrequest.priority"
      @valueUpdated="updateFieldValue"
    />
    <DynamicList
      label="Request Status"
      type="request_status"
      :except="except.none"
      :defaultValue="creditrequest.request_status"
      @valueUpdated="updateFieldValue"
    />
    <DynamicList
      label="Request Reason"
      type="request_reason"
      :except="except.none"
      :defaultValue="creditrequest.request_reason"
      @valueUpdated="updateFieldValue"
    />
    <f7-list>
      <f7-list-item>
        <f7-list-input
          label="Credit Amount Requested"
          type="number"
          placeholder="Credit Amount Requested..."
          clear-button
          :value="creditrequest.amount_requested"
          @input="creditrequest.amount_requested = $event.target.value"
        />
      </f7-list-item>
    </f7-list>
    <f7-block strong>
      <f7-row>
        <f7-col>
          <f7-button fill color="red" href="/listcreditrequest/"
            >Cancel</f7-button
          >
        </f7-col>
        <f7-col>
          <f7-button
            fill
            color="green"
            @click.prevent="submitRequest"
            :disabled="disabled"
            >Submit Request</f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>
import ShopList from "../../components/ShopList.vue";
import DynamicList from "../../components/DynamicList.vue";
import CustomersList from "../../components/Customers.vue";
export default {
  components: {
    ShopList,
    DynamicList,
    CustomersList,
  },
  data() {
    return {
      except: {
        none: [],
      },
      disabled: true,
      branch: "",
      userId: "",
      creditrequest: {
        company_name: "",
        customer_id: "",
        request_reason: "",
        customer_type: "",
        creditrequest_priority: "",
        priority: "",
        request_status: "",
        branch: "",
      },
    };
  },
  async created() {
    var user = this.$vlf.createInstance({
      storeName: "user",
    });
    user
      .getItem("id")
      .then((value) => (this.userId = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    user
      .getItem("branch")
      .then((value) => (this.creditrequest.branch = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
  },

  methods: {
    submitRequest() {
      const url = apiUrl + "/api/creditrequests/add";

      const router = this.$f7router;
      const data = {
        creditrequest:this.creditrequest,
        userId:this.userId
      } 
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.categories = response.data;
          console.log(response.data);
          router.back({
            force: true,
            ignoreCache: true,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateBranch(val) {
      this.creditrequest.branch = val;
      this.checkRequired();
    },
    updateFieldValue(val) {
      if (val.field == "creditrequest_priority") {
        val.field = "priority";
      }
      this.creditrequest[val.field] = val.value;
      console.log(this.creditrequest);
      this.checkRequired();
    },
    customerDetailsUpdate(val) {
      console.log(val);
      this.creditrequest.company_name = val.customer;
      if (val.list == "fbcustomer") {
        this.creditrequest.fbcustomer_id = val.customer_id;
      } else {
        this.creditrequest.customer_id = val.customer_id;
      }
      this.checkRequired();
      console.log(this.creditrequest);
    },

    checkRequired() {
      this.disabled = false;
      if (this.creditrequest.company_name == "") {
        this.disabled = true;
      }
      if (this.creditrequest.request_status == "") {
        this.disabled = true;
      }
      if (this.creditrequest.company_name == "") {
        this.disabled = true;
      }
      if (this.creditrequest.request_reason == "") {
        this.disabled = true;
      }
      if (this.creditrequest.customer_type == "") {
        this.disabled = true;
      }
      if (this.creditrequest.priority == "") {
        this.disabled = true;
      }
      if (this.creditrequest.branch == "") {
        this.disabled = true;
      }
    },
  },
};
</script>
<template>
  <f7-list>
    <f7-list-item
      title="Action"
      ref="action"
      class="action"
      smart-select
      :smart-select-params="{
        openIn: 'popup',
        searchbar: true,
        searchbarPlaceholder: 'Search',
      }"
    >
      <select name="action" @change="closeList()" v-model="action">
        <option
       
          v-for="action in actions"
          :id="action.id"
          :key="action.id"
          :value="action.id"
        >
     {{ action.value }}
        </option>
      </select>

      
    </f7-list-item>
  <f7-list-item>
    <button  
      v-for="action in actions"
          :id="action.id"
          :key="action.id"
          :value="action.id"
          :class="setActionClass(action.value)"
          @click="setAction(action.id,action.value)"
          >
          {{ action.value }}
          </button>
        </f7-list-item>
  </f7-list>
</template>
<style>
button{
  height:50px;
}
</style>
<script>
export default {
  props: {
    defaultBranch: [Number, String],
  },
  data() {
    return {
      title: "Action",
      actions: [],
      id: "",
      action: "",
    };
  },
  async created() {
    const data = {
      menu: "actions",
      except:['PENDING']
    };

    const url = apiUrl + "/api/dropdowns/dropdownlist";

    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        this.actions = response.data;
        console.log(response);
      })
      .finally(() => {
        // this.$refs.action.f7SmartSelect.setValue(this.defaultBranch);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    selected(action, id) {
      if (action == id) {
        return true;
      } else {
        return false;
      }
    },
    closeList() {
      // this.$emit("actionUpdated", );
      this.$refs.action.f7SmartSelect.close();
    },
    alt() {
      // alert(this.defaultBranch)
    },
    setActionClass(val){
      return val.toLowerCase().replace(' ','_');
    },
    setAction(id,val){
      this.action = id
      this.$emit("actionUpdated", val);
    this.$refs.action.f7SmartSelect.setValue(id);
    }
  },
  watch: {
    action: function (val) {
      this.$emit("actionUpdated", val);
    },
  },
};
</script>

<template>
  <!-- <input type="file" accept="image/*;capture=camera"> -->
  <div class="choose_file">
    <f7-block strong>
      <input
        name="img"
        style="display: none"
        type="file"
        @change="loadImage"
        accept="image/*"
        ref="imgInput"
      />
      <f7-button fill color="blue" @click="$refs.imgInput.click()"
        >Select Image or Take Picture</f7-button
      >
    </f7-block>
  </div>
</template>
<script>
import { allowedNodeEnvironmentFlags } from "process";

export default {
  props: {
    item: Object,
    params: {
      type: String,
      default: "",
    },
    uid: Number,
    model: String,
    back: {
      type: String,
      default: "false",
    },
  },
  data() {
    return {
      selectedFile: null,
      preview: null,
      tname: "tmpname",
    };
  },

  methods: {
    loadImage(event) {
      console.log(event);
      this.selectedFile = event.target.files[0];
      var name = prompt("Name Image", "TempName");
      this.tname = name;
      this.$f7.popup.close(".cam-popup");
      this.onupload();
      if (this.back == "true") {
        this.$f7router.back();
      }
    },
    onupload() {
      var id = this.item.id;

      const url = apiUrl + "/api/" + this.model + "/upload";

      const fd = new FormData();

      fd.append("doc", this.selectedFile, this.tname);
      fd.append("tname", this.tname);
      fd.append("id", id);
      fd.append("uid", this.uid);
      fd.append("params", this.params);

      console.log(fd);
      axios.post(url, fd).then((response) => {
        console.log(response);
        this.$emit("picList", response.data.pics);
        this.$emit("docList", response.data.docs);
      });
    },
  },
};
</script>
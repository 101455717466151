<template>
  <div>
    <f7-list>
      <f7-list-input
        label="Vendor"
        class="searchbar-demo tapHold"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keyup.native="searchVendors($event), showList()"
        @keydown.native="isTab($event)"
        @dblclick.native="clearValue()"
        @tapHold.native="clearValue()"
      ></f7-list-input>
    </f7-list>
    <f7-popover class="popover-vendor">
      <f7-list>
        <f7-list-item
          v-for="vendor in vendors"
          :key="vendor.id"
          :value="vendor.name"
          @click="selectVendor(vendor.id, vendor.name)"
          >{{ vendor.name }}</f7-list-item
        >
      </f7-list>
    </f7-popover>
  </div>
</template>
  
  <script>
export default {
  props: {},
  data() {
    return {
      vendorID: this.vendor_id,
      keyword: "",
      showall: true,
      fieldname: "vendor",
      vendors: [],
      vendor_name: "",
      vendor: "",
      vendor_detail: {
        vendor: "",
        vendor_id: "",
      },
      shop: "",
      first_name: "",
      last_name: "",
      show_all: "",
      limit: 10,
      custsch: false,
    };
  },

  async created() {
    this.vendor_id = this.$f7route.params.vendor_id;

    if (this.vendor_id != 0) {
      const data = {
        id: this.vendor_id,
      };
      const url = apiUrl + "/api/vendors/vendor";

      await axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.vendor_name = response.data.name;
          this.keyword = response.data.name;
          this.vendor = response.data.name;
          this.vendor_id = response.data.id;
          this.vendor_detail.vendor = response.data.name;
          this.vendor_detail.vendor_id = response.data.id;
          this.$emit("vendorDetails", this.vendor_detail);
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("false");
    }
  },

  methods: {
    isTab(e) {
      this.vendor = this.keyword;
      this.vendor_name = this.keyword;
      this.vendor_detail.vendor = this.keyword;
      this.vendor_detail.vendor_id = "";
      this.$emit("vendorDetails", this.vendor_detail);
    },
    searchVendors(e) {
      if (e.which == 13) {
        this.vendor = this.keyword;
        this.vendor_name = this.keyword;
        this.vendor_detail.vendor = this.keyword;
        this.vendor_detail.vendor_id = "";
        this.$emit("vendorDetails", this.vendor_detail);
      } else {
        this.vendor_name = this.keyword;

        const data = {
          keyword: this.keyword,
          limit: this.limit,
        };
        console.log(data);
        const url = apiUrl + "/api/vendors/vendorsearch";

        axios
          .post(url, data, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + TOKEN,
            },
          })
          .then((response) => {
            this.vendors = response.data;
            console.log(response);
            if (response.data.length < 1) {
              this.closeList();
            }

            console.log(response.data);
          })
          // .then((response) => (this.vacations = response.data.items))
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    selectVendor(id, name) {
      // console.log(id, name);
      this.keyword = name;
      this.vendor_name = name;
      this.vendor_id = id;
      this.vendor = name;
      this.vendor_detail.vendor = name;
      this.vendor_detail.vendor_id = id;
      this.$emit("vendorDetails", this.vendor_detail);
      this.closeList();
    },
    showList() {
      const app = this.$f7;
      app.popover.open(".popover-vendor");
    },
    closeList() {
      const app = this.$f7;
      app.popover.close(".popover-vendor");
    },
    clearValue() {
      this.keyword = "";
      this.vendor_name = "";
      this.vendor_id = "";
      this.vendor = "";
    },
  },
  watch: {
    vendor_details: function (val) {
      this.$emit("vendorDetails", this.vendor_detail);
    },
  },
};
</script>
  <style>
.popover-vendor{
  margin-top: 170px;
}
</style>
  
<template>
  <f7-list>
    <f7-list-item
     title="Shop"
      ref="shop"
      class="branch"
    
      smart-select
      :smart-select-params="{
        openIn: 'popup',
        searchbar: true,
        searchbarPlaceholder: 'Search',
      }"
    >
      <select name="branch" ref="branch"  @change="closeList()"  >
        <option
          v-for="shop in shops"
          :id="shop.id"
          :key="shop.id"
          :value="shop.id"
          @click="setShop(shop.id,shop.value)"
        >
          {{ shop.value }} 
        </option>
      </select>

    </f7-list-item>
    <f7-list-item v-if="buttons=='true'">
    <button  
      v-for="shop in shops"
          :id="shop.id"
          :key="shop.id"
          :value="shop.id"
          :class="setShopClass(shop.value)"
          @click="setShop(shop.id,shop.value)"
          >
          {{ shop.value }}
          </button>
        </f7-list-item>
  </f7-list>
</template>

<script>
export default {
  props: {
    defaultBranch:[Number,String],
    buttons: String,
  },
  data() {
    return {
      title: "Shop",
      shops: [],
      branch: 0,
     
    };
  },
  async created() {
    const data = {
      menu: "locations",
      except: ["Mobile 1", "No Branch", "Unknown"],
    };

    const url = apiUrl + "/api/dropdowns/dropdownlist";

    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        this.shops = response.data;
        console.log(response);
      })
      .finally(() => {
        this.$refs.shop.f7SmartSelect.setValue(this.defaultBranch);
  console.log('shop set')
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    selected(branch, id) {
      if (branch == id) {
        return true;
      } else {
        return false;
      }
    },
    closeList() {
      this.branch = this.$refs.branch.value;
      this.$refs.shop.f7SmartSelect.close();

      console.log(this.branch + 'a2')
    },
    alt() {
      // alert(this.defaultBranch)
    },
    setShopClass(val) {
      return val.toLowerCase().replace(" ", "_");
    },
    setShop(id, val) {
      this.branch = id;
      this.$emit("branchUpdated", id);
      this.$refs.shop.f7SmartSelect.setValue(id);
   },

  },
  watch: {
    branch: function (val) {
      console.log(val + 'a3')
      this.$emit("branchUpdated", val);
   
    },
    defaultBranch: function (val) {
      console.log(val + 'a4')
      // this.$refs.shop.f7SmartSelect.setValue(val);
      this.branch = val;
    }
  },
};
</script>

<template>
  <f7-page name="settings">
    <f7-navbar title="Settings" back-link="Back"></f7-navbar>
    <f7-block-title>Settings</f7-block-title>


    <f7-button @click="forceSWupdate">Check For Updates</f7-button>
  </f7-page>
</template>

<script>
  export default {
methods:{

//https://stackoverflow.com/questions/52221805/any-way-yet-to-auto-update-or-just-clear-the-cache-on-a-pwa-on-ios

//       forceSWupdate() {

//       const router = this.$f7router;

//       if ('serviceWorker' in navigator) {

//  navigator.serviceWorker.getRegistrations().then(function(registrations) {

//   registrations.map(r => {

//     console.log(r.unregister())


//   })
//  })

forceSWupdate () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        // registration.update()
        registration.unregister();
        alert('Updated. Close app and reopen for update to take affect.')
      }
    })

//  window.location.reload(true)
  const router = this.$f7router;
 router.navigate('/');
}

      }


}
};
</script>

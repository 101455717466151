<template>
  <f7-page name="partsrequestadd">
    <f7-navbar title="Add Parts Counter Request" back-link="Back"></f7-navbar>
    <f7-list media-list >
      <CustomerSearch
        :customer_id="customer_id"
        @customerDetails="customerDetailsUpdate"
      />
      <ShopList
        buttons="false"
        :defaultBranch="branch"
        @branchUpdated="updateBranch"
      />
      <DynamicInputs
      :partNumbers="partscounter.part_numbers"
       @updatePartNumbers="updatePartNo" 
       />
       </f7-list>
<f7-list media-list dividers-ios strong-ios outline-ios>
      <f7-list-item >
        <!-- <f7-list-input
          label="Part Number"
          type="text"
          placeholder="Part number..."
          clear-button
          :value="partscounter.part_no"
          @input="partscounter.part_no = $event.target.value"
        >
        </f7-list-input> -->
        <f7-list-input
          label="Details"
          type="text"
          placeholder="Details"
          clear-button
          :value="partscounter.details"
          @input="partscounter.details = $event.target.value"
        >
        </f7-list-input>
      </f7-list-item>
      <f7-list-item title="Vehicle Details">
        <f7-list-input
          label="Year"
          type="text"
          placeholder="Year"
          clear-button
          :value="partscounter.vehicle.year"
          @input="partscounter.vehicle.year = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="Make"
          type="text"
          placeholder="Make"
          clear-button
          :value="partscounter.vehicle.make"
          @input="partscounter.vehicle.make = $event.target.value"
        >
          ></f7-list-input
        >
        <f7-list-input
          label="Model"
          type="text"
          placeholder="Model"
          clear-button
          :value="partscounter.vehicle.model"
          @input="partscounter.vehicle.model = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="VIN"
          type="text"
          placeholder="VIN"
          clear-button
          :value="partscounter.vehicle.vin"
          @input="partscounter.vehicle.vin = $event.target.value"
        ></f7-list-input>
      </f7-list-item>

      <f7-list-item title="Special Instructions">
        <f7-list-input
          type="textarea"
          placeholder="Special Instructions"
          clear-button
          :value="partscounter.special_instructions"
          @input="partscounter.special_instructions = $event.target.value"
        ></f7-list-input>
      </f7-list-item>
      </f7-list>
      <f7-list>
      <UrgencyList 
      :urgencyDetails="urgencyDetails"
      @urgencyUpdated="updateUrgency" 
      />
    </f7-list>
    <f7-block strong>
      <f7-row>
        <f7-col>
          <f7-button fill color="green" @click.prevent="submitRequest"
            >Submit</f7-button
          >
        </f7-col>
        <f7-col>
          <f7-button fill color="red" @click="$f7router.back()"
            >Cancel</f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-toolbar bottom class="bottom-toolbar">
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>

      <!-- <f7-link v-on:click="upload_file"
              ><i class="icon f7-icons color-blue">square_arrow_up</i></f7-link
            > -->
      <f7-link v-on:click="toggleNote"
        ><i class="icon f7-icons color-blue">chat_bubble_text</i></f7-link
      >
      <f7-link popup-open=".cam-popup"
        ><i class="icon f7-icons color-blue">camera</i></f7-link
      >
    </f7-toolbar>
  </f7-page>
</template>
    <script>
import CustomerSearch from "../../components/CustomerSearch.vue";
import DynamicInputs from "../../components/DynamicInputs.vue";
import ShopList from "../../components/ShopList.vue";
import UrgencyList from "../../components/UrgencyList.vue";

export default {
  components: {
    CustomerSearch,
    ShopList,
    DynamicInputs,
    UrgencyList,
  },
  data() {
    return {
      urgencyDetails:{
        menu: 'partscount_urgency',
        first: false,
        except:['No Hurry'],
        buttons:true,
        default:'217'
      },
      customer_id: 0,
      componentKey: 0,
      model: "Partscounters",
      isEditing: false,
      id: "",
      addTimes: false,
      shop: "cov",
      email: "",
      tz: "-05:00",
      customer: "",
      partscounter: {
        part_no: "",
        details: "",
        vehicle: {},
        part_numbers: [
          {
            partno: "",
            qty: "",
          },
        ],
      },
      notes: [],
      pics: "",
      picCount: "",
      noteCount: "",
      branch: 0,
      user: {
        first_name: "",
        last_name: "",
        id: 0,
        shop: "",
        token: "",
        shopName: "",
        urgency:"",
      },
      domain: apiUrl,
      fileUrl: "/webroot/files/Partscounters/pics/",
    };
  },

  async mounted() {
    this.customer_id = this.$f7route.params.custid;
    // this.shop = this.$f7route.params.shop;
    console.log(this.customer_id + " cust id");
    this.domain = apiUrl;
    // },

    // async created() {
    // this.customer_id = this.$f7route.params.custid;
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    userStore
      .getItem("first_name")
      .then((value) => (this.user.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("last_name")
      .then((value) => (this.user.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("shop")
      .then((value) => (this.user.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("token")
      .then((value) => (this.user.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("branch")
      .then((value) => (this.branch = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("shopName")
      .then((value) => (this.user.shopName = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    console.log(this.branch + "a1");
    const url = apiUrl + "/api/partscounters/request";

    console.log(this.user + " usr");
  },

  methods: {
    addRequest() {
      const url = apiUrl + "/api/partscounters/addrequest";

      const router = this.$f7router;

      const data = {
        userid: this.user.id,
        customer: this.partscounter.customer,
        customer_id: this.partscounter.customer_id,
        part_numbers: this.partscounter.part_numbers,
        part_no: this.partscounter.part_no,
        details: this.partscounter.details,
        branch: this.branch,
        requestor: this.user.first_name + " " + this.user.last_name,
        requestor_id: this.user.id,
        vehicle: JSON.stringify(this.partscounter.vehicle),
        special_instructions: this.partscounter.special_instructions,
        urgency: this.partscounter.urgency
      };

      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response.data);
          router.navigate("/partscounterrequest/" + response.data.id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    customerDetailsUpdate(val) {
      this.partscounter.customer = val.customer;
      this.partscounter.customer_id = val.customer_id;
    },
    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },

    myShop() {
      if (this.tool._current_location.value === this.user.shopName) {
        return true;
      } else {
        return false;
      }
    },
    toggleNote() {
      alert("add note");
    },
    setPics(pics) {
      this.pics = pics;
      this.picCount = this.setBadge(pics.length);
    },
    setNoteCount(noteCount) {
      console.log(noteCount);
      this.noteCount = this.setBadge(noteCount);
    },
    setNotes(notes) {
      console.log(notes);
      this.notes = notes;
    },
    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    formatTitle(val) {
      val = val.split("_");
      return val[2];
    },
    updateBranch(val) {
      this.branch = val;
      console.log("branch3:" + this.branch);
    },

    ucaseWords(val) {
      return val.toUpperCase();
    },
    formatTime(time) {
      var time = new Date(time);

      return this.$moment(time).format("MM-DD-YYYY h:mm A");
    },
    test() {
      console.log(this.partscounter);
    },
    submitRequest() {
      this.addRequest();
    },
    updatePartNo(val) {
      this.partscounter.part_numbers = val;
      console.log("update part numbers")
      console.log(this.partscounter);
    },
    updateUrgency(val) {
      this.partscounter.urgency = val;
     
    },
  },
};
</script>
    <style>
.view {
  border-color: transparent;
  background-color: initial;
  color: initial;
}
.tool_img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  float: left;
}
.transfer_alert {
  background-color: yellow;
}
.space {
  padding-bottom: 100px;
}
.vh_key {
  margin-right: 10px;
  font-size: 0.8em;
}
.vh_val {
  font-size: 0.8em;
}
</style>
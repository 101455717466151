<template>
  <div id="this-salescall">
    <f7-page name="salescall">
      <f7-navbar>
        <f7-nav-left>
          <f7-link
            icon-ios="f7:chevron_left"
            icon-md="material:chevron_left"
            :href="'/' + link.back + '/' + customerid"
            >Back</f7-link
          >
        </f7-nav-left>
        <f7-nav-title> Sales Call </f7-nav-title>
      </f7-navbar>

      <f7-card
        outline
        :title="
          customer.company_name +
          ' -  Visit by ' +
          salescall.user.full_name +
          ' on ' +
          formatDate(salescall.created)
        "
        @taphold.native="testTap"
      >
      </f7-card>
      <div class="upper-area">
        <f7-list accordion-list inset class="note-accordion">
          <f7-list-item
            id="note"
            accordion-item
            title="Notes"
            :badge="noteCount"
            badge-color="red"
          >
            <f7-accordion-content id="">
              <Notes
                v-if="loadNotes == true"
                :model="model"
                :notes="salescall.notes"
                :userid="user.id"
                :entid="salescall.id"
                @notesCount="setNoteCount"
                @notesUpdate="noteRecord"
                @updateNotes="setNotes"
              />
            </f7-accordion-content>
          </f7-list-item>

          <f7-list-item
            accordion-item
            title="Pictures"
            :badge="picCount"
            badge-color="red"
          >
            <f7-accordion-content>
              <f7-swiper
                v-if="pics.length > 0"
                pagination
                navigation
                scrollbar
                :loop="false"
                :speed="500"
                :slidesPerView="1"
                :spaceBetween="20"
              >
                <f7-swiper-slide
                  v-for="(pic, index) in pics"
                  :key="index"
                  :title="pic"
                  @taphold.native="deletePic(pic)"
                >
                  <figure>
                    <figcaption>{{ formatTitle(pic) }}</figcaption>
                    <img
                      class="tapHold"
                      :src="domain + fileUrl + pic"
                      @dblclick="deletePic(pic)"
                    />
                  </figure>
                </f7-swiper-slide>
                <f7-swiper-slide
                  ><div v-on:click="opencam2">
                    <div>Add image...</div>
                    <i class="icon f7-icons color-blue slider_add">camera</i>
                  </div></f7-swiper-slide
                >
              </f7-swiper>

              <f7-block v-else-if="pics.length == 0" class="upload_icon">
                <div v-on:click="opencam2">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div>
              </f7-block>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
        <f7-popup id="camera2" class="cam-popup">
          <!-- <f7-popup id="camera" class="cam-popup" :opened="popupOpened" @popup:closed="popupOpened = false"> -->
          <f7-page>
            <f7-navbar title="Upload Pictures">
              <f7-nav-right>
                <f7-link popup-close>Close</f7-link>
              </f7-nav-right>
            </f7-navbar>

            <Camera
              :model="model"
              :item="salescall"
              :uid="user.id"
              @picList="setPics"
            />
          </f7-page>
        </f7-popup>
      </div>

      <f7-toolbar bottom class="bottom-toolbar">
        <f7-link href="/"
          ><i class="icon f7-icons color-blue">house</i></f7-link
        >

        <f7-link
          ><i class="icon f7-icons color-blue">square_arrow_up</i></f7-link
        >
        <f7-link
          ><i class="icon f7-icons color-blue" @click="toggleNote"
            >chat_bubble_text</i
          ></f7-link
        >
        <f7-link
          ><i class="icon f7-icons color-blue" @click="opencam2"
            >camera</i
          ></f7-link
        >
      </f7-toolbar>
    </f7-page>
  </div>
</template>
  <script>
import Buttons from "../../components/Buttons.vue";
import Notes from "../../components/Notes.vue";
import Camera from "../../components/CameraComp.vue";

import { dateTimeMixin } from "../../mixins/dateTimeMixin.js";
import { pictureMixin } from "../../mixins/pictureMixin.js";
export default {
  mixins: [pictureMixin, dateTimeMixin],
  components: {
    Buttons,
    Notes,
    Camera,
  },
  data() {
    return {
      referer: "undefined",
      customerid: "",
      id: "",
      token: "",
      customer: {
        company_name: "Test",
      },
      salescall: {
        user: [],
        created: "",
      },
      yearly_sales: "",
      ytd_sales: "",
      noteCount: "",
      notes: [],
      model: "Salescalls",
      loadNotes: false,
      user: {
        first_name: "",
        last_name: "",
        id: 0,
        shop: "",
        token: "",
      },
      pics: [],
      picCount: "",
      domain: "",
      fileUrl: "/webroot/files/Salescalls/doc/",
      notefocus: false,
      modl: "",
      link: {
        back: "",
      },
    };
  },
  mounted() {
    this.id = this.$f7route.params.id;
    this.customerid = this.$f7route.params.customerid;
    this.modl = this.$f7route.params.model;
    this.referer = this.$f7route.params.referer;
    if (this.referer !== "undefined") {
      this.link.back = this.referer;
    } else {
      switch (this.modl) {
        case "prospects":
          this.link.back = "prospect";
          break;
        case "fbcustomers":
          this.link.back = "customer";
          break;
      }
    }

    l;
  },
  async created() {
    var user = this.$vlf.createInstance({
      storeName: "user",
    });
    await user
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await user
      .getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    const data = {
      id: this.$f7route.params.id,
      customerid: this.$f7route.params.customerid,
      user_id: this.user.id,
      modl: this.modl,
    };
    this.domain = apiUrl;
    console.log(this.domain);
    const url = apiUrl + "/api/salescalls/salescall";

    console.log(this.token);
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        console.log(response);
        this.salescall = response.data.salescall;
        if (this.modl == "fbcustomers") {
          this.customer = response.data.salescall.fbcustomer;
        }
        if (this.modl == "prospects") {
          this.customer = response.data.salescall.prospect;
        }
        this.notes = response.data.salescall.notes;
        this.loadNotes = true;
        this.pics = response.data.pics;
        this.picCount = this.setBadge(response.data.pics.length);
        console.log(this.pics);
        this.noteCount = this.setBadge(response.data.salescall.notes.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    opencam2() {
      const app = this.$f7;

      app.popup.open("#camera2");
    },

    setNoteCount(noteCount) {
      console.log(noteCount);

      this.noteCount = this.setBadge(noteCount);
    },
    noteRecord(notesUpdate) {
      console.log(notesUpdate);
      // this.salescall.notes = notesUpdate;
      // console.log(notesUpdate + 'here')
    },
    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    toggleNote() {
      const app = this.$f7;
      app.accordion.open(".note-accordion");
    },
    testTap() {
      alert("yes");
    },
    setNotes(notes) {
      console.log(notes);
      this.salescall.notes = notes;
    },
    deletePic(pic) {
      var del = confirm("Delete this image?");

      if (del == true) {
        this.sendDeleteRequest(pic);
      }
    },
    deleteDoc(doc) {
      var del = confirm("Delete this document?");

      if (del == true) {
        this.sendDeleteRequest(doc);
      }
    },
    sendDeleteRequest(filename) {
      const data = {
        filename: filename,
      };
      console.log(data);
      const url = apiUrl + "/api/salescalls/deletedoc";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response);
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
          this.docs = response.data.docs;
          this.docCount = this.setBadge(response.data.docs.length);
          // console.log(response)
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style>
figcaption {
  display: block;
  font-size: 0.8em;
  padding-top: 0.5em;
  position: absolute;
  bottom: 0;

  left: 25%;
}
</style>
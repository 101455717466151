<template>

    <f7-page name="addprospect" login-screen>
      <f7-navbar title="Add Prospect" back-link="Back"></f7-navbar>
      <f7-block-title class="text-align-center"
        ><h2>Prospect Contact</h2></f7-block-title
      >
      <f7-card>
      <f7-list no-hairlines-md form>
        <f7-list-input
          label="Company Name"
          type="text"
          name="company_name"
          id="company_name"
          placeholder="Company Name"
          :value="prospect.company_name"
          @input="prospect.company_name = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="Main Phone"
          type="text"
          placeholder="Main Phone"
          name="main_phone"
          :value="prospect.main_phone"
          @input="prospect.main_phone = verifyPhone($event.target.value)"
        ></f7-list-input>
        <f7-list-input
          label="Details"
          type="textarea"
          placeholder="Details"
          name="details"
          :value="prospect.details"
          @input="prospect.details = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="First Name"
          type="text"
          name="first_name"
          id="contact_first_name"
          placeholder="First Name"
          :value="prospect.first_name"
          @input="prospect.contact_first_name = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Last Name"
          type="text"
          :value="prospect.contact_last_name"
          @input="prospect.last_name = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          label="Phone"
          type="text"
          placeholder="Phone"
          name="contact_phone"
          :value="prospect.contact_phone"
          @input="prospect.contact_phone = verifyPhone($event.target.value)"
        ></f7-list-input>

        <f7-list-input
          label="Email"
          type="email"
          placeholder="Email"
          name="email_address"
          :class="emailVerified"
          :value="prospect.email_address"
          @input="prospect.email_address = $event.target.value"
          @blur="verifyEmail($event.target.value)"
        ></f7-list-input>
    
      <f7-list-input
          label="Address Line 1"
          type="text"
          placeholder="Line 1"
          name="physical_address_line_1"
          :value="prospect.physical_address_line_1"
          @input="prospect.physical_address_line_1 = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="Address Line 2"
          type="text"
          placeholder="Line 2"
          name="physical_address_line_2"
          :value="prospect.physical_address_line_2"
          @input="prospect.physical_address_line_2 = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="City"
          type="text"
          placeholder="City"
          name="physical_address_city"
          :value="prospect.physical_address_city"
          @input="prospect.physical_address_city = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="State"
          type="text"
          placeholder="State"
          name="physical_address_state"
          :value="prospect.physical_address_state"
          @input="prospect.physical_address_state = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          label="Zip"
          type="text"
          placeholder="Zip"
          name="physical_address_zip"
          :value="prospect.physical_address_zip"
          @input="prospect.physical_address_zip = $event.target.value"
        ></f7-list-input>




      </f7-list>



      <f7-list>
        <f7-row tag="p">
          <f7-button
            class="col"
            fill
            large
            raised
            @click.prevent="submitRequest"
            color="green"
            >Submit
          </f7-button>
        </f7-row>

        <f7-row tag="p">
          <f7-button
            class="col"
            fill
            large
            raised
            color="red"
            @click="$f7router.back()"
            >Cancel</f7-button
          >
        </f7-row>
      </f7-list>
    </f7-card>
    </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      userid:"",
      prospect:{
        company_name:'',
        main_phone:'',
        contact_first_name:'',
        contact_last_name:'',
        contact_phone:'',
        details:'',
        email_address:'',
        physical_address_line_1:'',
        physical_address_line_2:'',
        physical_address_city:'',
        physical_address_state:'',
        physical_address_zip:'',	
      },
      email: "",
      emailVerified: "",
    };
  },
  async created(){


const userStore =  this.$vlf.createInstance({
           storeName: 'user'
       });
await userStore
   .getItem("id")
   .then((value) => (this.userid = value))
   .catch(function (err) {
     // This code runs if there were any errors
     console.log(err);
   });
  },
  methods: {
    verifyPhone(val) {
      if (val.length == 10 || val == "") {
        var text = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        return text;
      } else {
        return val;
      }
    },
    verifyEmail(val) {
      var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
      if (testEmail.test(val)) {
        this.emailVerified = "";
      } else {
        this.emailVerified = "emailNot";
      }
    },
    submitRequest() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      const addUrl = apiUrl + "/api/prospects/addprospect";
      const data = {
        prospect:this.prospect,
        userid:this.userid
      }
      console.log(data)
      axios
        .post(addUrl, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + TOKEN,
          },
        })
        .then((response) => console.log(response))
        .then(function () {
          router.back({
            force: true,
            ignoreCache: true,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.emailNot {
  color: red;
  border: 2px solid red;
}
</style>







</script>
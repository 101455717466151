<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar large :sliding="false">
      <f7-nav-left>
        <!-- <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link> -->
      </f7-nav-left>
      <f7-nav-title sliding>Servco Mobile</f7-nav-title>
      <f7-nav-right>
        <f7-link no-link-class href="/profile/"
          ><span>{{ first_name }} {{ last_name }}</span></f7-link
        >
      </f7-nav-right>
      <f7-nav-title-large>Servco Mobile</f7-nav-title-large>
    </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
      <!-- <f7-link>Left Link</f7-link>
      <f7-link>Right Link</f7-link> -->
    </f7-toolbar>

    <!-- Page content-->

    <f7-block-title>Navigation</f7-block-title>
    <f7-list>
      <!-- <f7-list-item link="/about/" title="About"></f7-list-item> -->
      <f7-list-item
        link="/employeeportal/"
        title="Employee Portal"
      ></f7-list-item>
      <f7-list-item :link="'/mytasks/' + uid" title="Tasks"></f7-list-item>
      <f7-list-item
        link="/partsdaily/"
        title="Daily Parts"
      ></f7-list-item>
      <f7-list-item link="/links/" title="Links"></f7-list-item>
      <!-- <f7-list-item link="/login/" title="Login"></f7-list-item> -->
      
      <!-- <f7-list-item link="/parts/" title="Daily Parts"></f7-list-item> -->
      <f7-list-item link="/transfers/" title="Order Transfers"></f7-list-item>
      <!-- <f7-list-item link="/task/3" title="Task Test"></f7-list-item>
        <f7-list-item link="/uploadfiles/3" title="Upload Files"></f7-list-item> -->
      <f7-list-item
        v-if="checkAccess(['Outside Sales', 'manager', 'admin'], access)"
        link="/customerdashboard/"
        title="Customer Dashboard"
      ></f7-list-item>
      <f7-list-item
        link="/maintenancerequest/maintenance"
        title="Maintenance Request"
      ></f7-list-item>
      <f7-list-item
        link="/maintenancerequest/tool"
        title="New Tool Request"
      ></f7-list-item>
      <f7-list-item link="/toollist/" title="Tool List"></f7-list-item>
      <f7-list-item
        v-if="checkAccess(['admin'], access)"
        link="/inspectionlist/"
        title="Inspections"
      ></f7-list-item>
      <f7-list-item
        v-if="checkAccess(['admin','Parts'], access)"
        link="/partscounter/"
        title="Parts Counter"
      ></f7-list-item>
      <f7-list-item link="/settings/" title="Settings"></f7-list-item>
      <f7-list-item link="/listcreditrequest/" title="Credit Request List"></f7-list-item>
      <!-- <f7-list-item link="/addcreditrequest/" title="Credit Request Add"></f7-list-item> -->
    </f7-list>
  </f7-page>
</template>

<script>
import { accessMixin } from "../mixins/accessMixin.js";
export default {
  mixins: [accessMixin],
  data: function () {
    return {
      first_name: "",
      last_name: "",
      uid: "",
      access: '',
    };
  },

  mounted() {
    console.log(ACCESS + '****' + TOKEN + ' ****')
    this.access = ACCESS;
    var user = this.$vlf.createInstance({
      storeName: "user",
    });

    user
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    user
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    user
      .getItem("id")
      .then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

          var accessStore =  this.$vlf.createInstance({
          storeName: 'access'
      });
   
  },
  methods: {},
};
</script>
